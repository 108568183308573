import React, { Component } from 'react';
import { CardDeck, Card, CardHeader, CardBody, Row, Col } from 'reactstrap';

import withApp from './app';
import Actions from '../components/actions';
import Organizations from '../components/organizations';
import { getData } from 'core/ducks/update';
import { Recent, MessageModal } from 'app-messages';
import { readMessage } from 'app-messages/ducks/messages';
import T from 'modules/i18n';

import '../../scss/style.scss';

class AppDashboard extends Component {

	constructor(props) {
		super(props);
		this.state = {
			isMessageModalOpen: false,
			activeMessageContent: {},
		};
	}

	closeMessageModal = () => {
		const { isMessageModalOpen, activeMessageContent } = this.state;
		if (isMessageModalOpen) {
			this.props.dispatch(readMessage(activeMessageContent.uuid));
			this.setState({
				isMessageModalOpen: false,
				activeMessageContent: {},
			});
		}
	}

	handleMessageClick = (uuid) => {
		this.props.dispatch( getData(`appmessages/message/uuid/${uuid}`) )
			.then(activeMessageContent => {
				this.setState({
					isMessageModalOpen: true,
					activeMessageContent,
				});
			});
	}

	render() {
		return (
			<React.Fragment>
				<div className="app-dashboard d-none d-md-block full-content py-3 px-5">
					<CardDeck className="mb-4 h-50">
						<Card className="dashboard-card mh-100">
							<CardHeader className="text-info fw-semibold"><T>actions</T></CardHeader>
							<CardBody className="d-flex justify-content-start py-xxl-4">
								<Actions/>
							</CardBody>
						</Card>
						<Card className="dashboard-card mh-100">
							<CardHeader className="text-info fw-semibold"><T>relevant organizations</T></CardHeader>
							<CardBody>
								<Organizations/>
							</CardBody>
						</Card>
					</CardDeck>
					<CardDeck className="h-50">
						<Card className="dashboard-card mh-100">
							<CardHeader className="text-info fw-semibold"><T>unread messages</T></CardHeader>
							<CardBody>
								{ this.props.messages && this.props.messages.unread.length > 0 ?
									<Recent messages={this.props.messages.unread} onMessageClick={this.handleMessageClick}/>
									:
									<span className="text-muted"><T>no unread messages</T></span>
								}
							</CardBody>
						</Card>
					</CardDeck>
				</div>
				<div className="app-dashboard d-block d-md-none">
					<Row className="mt-2">
						<Col>
							<Card className="dashboard-card">
								<CardHeader className="text-info fw-semibold"><T>actions</T></CardHeader>
								<CardBody className="d-flex justify-content-start">
									<Actions/>
								</CardBody>
							</Card>
						</Col>
					</Row>
					<Row className="mt-2">
						<Col>
							<Card className="max-vh-50 dashboard-card">
								<CardHeader className="text-info fw-semibold"><T>relevant organizations</T></CardHeader>
								<CardBody>
									<Organizations/>
								</CardBody>
							</Card>
						</Col>
					</Row>
					<Row className="mt-2">
						<Col>
							<Card className="dashboard-card">
								<CardHeader className="text-info fw-semibold"><T>unread messages</T></CardHeader>
								<CardBody>
									{ this.props.messages && this.props.messages.unread.length > 0 ?
										<Recent messages={this.props.messages.unread} onMessageClick={this.handleMessageClick}/>
										:
										<span className="text-muted"><T>no unread messages</T></span>
									}
								</CardBody>
							</Card>
						</Col>
					</Row>
					<MessageModal isOpen={this.state.isMessageModalOpen} toggle={this.closeMessageModal} {...this.state.activeMessageContent} role={this.props.role}/>
				</div>
			</React.Fragment>
		);
	}
}

export default withApp(AppDashboard);
