import React, { Component } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import PropTypes from 'prop-types';

class Radio extends Component {

	constructor(props) {
		super(props);
		this.state = {value: props.value}
		this.handleChange = this.handleChange.bind(this);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const { value } = this.props;
		if ( prevProps.value !==  value) {
			this.setState({
				value: value
			})
		}
	}

	handleChange(event) {
		const target = event.target;
		this.setState({value: target.value});
		this.props.onChange({target: {name: this.props.name, value: target.value}});
	}

	render() {
		const {required, name, readOnly, disabled} = this.props;

		let input_field = this.props.text && Object.entries(this.props.text).map(([key, value]) => {
			return (
				<FormGroup key={`form_group_${value}`} className="mb-1" check >
					<Label check >
						<Input
							type="radio"
							required={required}
							name={name}
							checked={this.state.value===key}
							value={key}
							onChange={this.handleChange}
							disabled={readOnly || disabled}
						/>
						<span dangerouslySetInnerHTML={{__html: value}}/>
					</Label>
				</FormGroup>
			);
		});

		return <FormGroup tag="fieldset" row>{input_field}</FormGroup>;
	}

}

Radio.propTypes = {
	value: PropTypes.string.isRequired,
	required: PropTypes.bool.isRequired,
	name: PropTypes.string.isRequired,
	text: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.array,
	]).isRequired,
	onChange: PropTypes.func.isRequired,
	readOnly: PropTypes.bool,
};

export default Radio;
