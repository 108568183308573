import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Nav, NavItem, NavLink, TabContent, TabPane, Button, Row, Col, Toast, ToastHeader, ToastBody, List, Badge } from 'reactstrap';

import { getParameters } from 'core/model/lib/urlTools';
import { routes } from '../../model/routes';
import { ErrorPage } from 'core/views/pages';
import { getData } from 'core/ducks/update';
import { SecureContent } from 'core/components';
import * as roles from 'core/model/roles';
import { Snippet, MessageModal, NewMessageModal } from 'app-messages';
import { readMessage } from 'app-messages/ducks/messages';
import { applicationStatusStyle } from '../../model/applicationStatus';
import T from 'modules/i18n';

class ApplyWrapper extends Component {
	render() {
		const { application, round } = getParameters(this.props.location.pathname, routes);
		return <Apply {...this.props} key={`application_sidebar_${round}_${application}`}/>
	}
}

class Apply extends Component {

	constructor(props) {
		super(props);
		this.state = {
			activeTab: '0',
			applicationData: {},
			organizationData: {},
			httpStatus: 200,
			messages: [],
			activeMessageContent: {},
			isMessageModalOpen: false,
			isNewMessageModalOpen: false,
		};
	}

	componentDidMount() {
		const { application } = getParameters(this.props.location.pathname, routes);
		this.props.dispatch( getData(`flows/application/uuid/${application}`) )
			.then(applicationData => {
				this.props.dispatch( getData(`app/member/user/${applicationData.author_uuid}/organization/${applicationData.org_uuid}/role/proxy`))
					.then(response => { 
						const memberDetails = (Array.isArray(response) && response.length == 1) ? response[0] : {email: null, phonenumber: null}
						this.setState({applicationData: {...applicationData, author_email: memberDetails.email, author_phone: memberDetails.phonenumber}})
					});
				this.props.dispatch( getData(`app/organization/uuid/${applicationData.org_uuid}`) )
					.then(organizationData => this.setState({organizationData}));
			}).catch(httpStatus => this.setState({httpStatus}));
		this.getMessages();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.messages && prevProps.messages.length < this.props.messages.length) {
			const { application } = getParameters(this.props.location.pathname, routes);
			const prev = prevProps.messages.map(m => m.uuid);
			const newMessages = this.props.messages
				.filter(m => !prev.includes(m.uuid))
				.filter(m => m.application === application).length;
			if (newMessages > 0)
				this.getMessages();
		}
	}

	getMessages = () => {
		const { role } = this.props;
		if (role === roles.GUEST || role === roles.AUTHORIZED)
			return
		const { application } = getParameters(this.props.location.pathname, routes);
		this.props.dispatch( getData(`appmessages/message/application/${application}`) )
			.then(messages => this.setState({messages, activeTab: messages.length > 0 ? '2' : this.state.activeTab}))
	}

	toggle = (activeTab) => {
		if (this.state.activeTab !== activeTab)
			this.setState({activeTab});
	}

	handleSnippetClick = (uuid) => {
		this.props.dispatch( getData(`appmessages/message/uuid/${uuid}`) )
			.then((activeMessageContent) => {
				this.setState({
					isMessageModalOpen: true,
					activeMessageContent,
					messages: [
						...this.state.messages.map(m => ({...m, read: (m.uuid === uuid && m.sender !== this.props.organization) ? true : m.read}))
					],
				});
				this.props.dispatch(readMessage(uuid));
			});
	}

	closeMessageModal = () => {
		this.setState({
			isMessageModalOpen: false,
			activeMessageContent: {},
		});
	}

	toggleNewMessageModal = () => {
		this.setState({
			isNewMessageModalOpen: !this.state.isNewMessageModalOpen,
		});
	}

	onNewMessage = () => {
		this.toggleNewMessageModal();
		this.getMessages();
	}

	render() {
		if (this.state.httpStatus !== 200)
			return (<ErrorPage status={this.state.httpStatus}/>);

		const { application } = getParameters(this.props.location.pathname, routes);

		const { applicationData, organizationData } = this.state;

		return (
			<SecureContent role={roles.REVIEWER}>
				<React.Fragment>
					<Nav tabs>
						<NavItem>
							<NavLink
								className={this.state.activeTab==='0' ? 'active text-info p-2' : 'border border-secondary p-2'}
								onClick={() => { this.toggle('0'); }}
							>
								<T>summary</T>
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								className={this.state.activeTab==='1' ? 'active text-info p-2' : 'border border-secondary p-2'}
								onClick={() => { this.toggle('1'); }}
							>
								<T>organization</T>
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								className={this.state.activeTab==='2' ? 'active text-info p-2' : 'border border-secondary p-2'}
								onClick={() => { this.toggle('2'); }}
							>
								<T>messages</T>
							</NavLink>
						</NavItem>
					</Nav>
					<TabContent activeTab={this.state.activeTab} className="scroller mx-2 mt-2">
						<TabPane tabId="0">
							<Row className="font-weight-bold border-bottom"><Col>{applicationData.organization}</Col></Row>
							{ this.props.context.round &&
								<Row className="text-right py-2">
									<Toast>
										<ToastHeader>{this.props.context.round.label}</ToastHeader>
										<ToastBody>
											<Badge color={this.props.context.round.active ? 'success' : 'warning'}>
												<T>{this.props.context.round.active ? 'active' : 'inactive'}</T>
											</Badge>
											<small className="text-muted font-weight-bold d-block"><T lettercase="capitalize">from</T></small>
											{this.props.context.round.starts}
											<small className="text-muted font-weight-bold d-block"><T lettercase="capitalize">to</T></small>
											{this.props.context.round.ends}
										</ToastBody>
									</Toast>
								</Row>
							}
							<Row className="py-2">
								<Col>
									<small className="text-muted font-weight-bold d-block"><T lettercase="capitalize">proxy</T></small>
									{ applicationData.author_email && 
										<a href={`mailto:${applicationData.author_email}`} title={applicationData.author_email} className="mr-1">
												<i className="fa fa-envelope-o"/>
										</a>
									}
									{applicationData.author}
									{ applicationData.author_phone &&
										<span className="d-block">
											<i className="fa fa-phone"/> <a href={`tel:${applicationData.author_phone}`}>{applicationData.author_phone}</a>
										</span>
									}
								</Col>
							</Row>
							<Row className="py-2">
								<Col>
									<small className="text-muted font-weight-bold d-block"><T lettercase="capitalize">reviewer</T></small>
									{applicationData.reviewer_uuid ? applicationData.reviewer : '-'}
								</Col>
							</Row>
							<Row className="py-2">
								<Col>
									<small className="text-muted font-weight-bold d-block"><T lettercas="capitalize">created</T></small>
									{applicationData.created}
								</Col>
							</Row>
							<Row className="py-2">
								<Col>
									<small className="text-muted font-weight-bold d-block"><T lettercase="capitalize">status</T></small>
									<Badge color={applicationStatusStyle[applicationData.status] ? applicationStatusStyle[applicationData.status].badge : 'info'}>
										<T>{applicationData.status}</T>
									</Badge>
								</Col>
							</Row>
							<Row className="py-2">
								<Col>
									<small className="text-muted font-weight-bold d-block"><T>registration_number</T></small>
									{applicationData.registry_number ? applicationData.registry_number : '-'}
								</Col>
							</Row>
							<Row className="py-2">
								<Col>
									<small className="text-muted font-weight-bold d-block"><T lettercase="capitalize">licence_number</T></small>
									{applicationData.licence_number ? applicationData.licence_number : '-'}
								</Col>
							</Row>
							<Row className="py-2">
								<Col>
									<small className="text-muted font-weight-bold d-block"><T lettercase="capitalize">external_url</T></small>
									{applicationData.external_url ? <Button className="mx-1" color="info" onClick={() => window.open(applicationData.external_url, "_blank")}>{applicationData.serial}</Button> : '-'}
								</Col>
							</Row>
							
						</TabPane>
						<TabPane tabId="1">
							<Row>
								<Toast className="p-2 text-center">
									<ToastHeader>
										{organizationData.title}
									</ToastHeader>
									<ToastBody>
										<List type="unstyled">
											<li className="text-right mb-3">
												<T>vat number</T> <span className="font-weight-bold">{organizationData.vat_number}</span> ({organizationData.country})
											</li>
											<li className="my-2 py-1">
												<i className="d-block fa fa-location-arrow m-1"/>
												<a className="no-decoration" target="_blank" href={`https://maps.google.com?q=${organizationData.address}`}>
													{organizationData.address}
												</a>
											</li>
											<li className="my-2 py-1">
												<i className="d-block fa fa-envelope-o m-1"/>
												<a className="no-decoration" href={`mailto:${organizationData.email}`}>{organizationData.email}</a>
											</li>
											<li className="my-2 py-1">
												<i className="d-block fa fa-phone m-1"/>
												<a className="no-decoration" href={`tel:${organizationData.phonenumber}`}>{organizationData.phonenumber}</a>
											</li>
											{ (organizationData.attachments && organizationData.attachments.length > 0) &&
												<li className="my-2 py-2 border-top">
													<i className="d-block fa fa-files-o fa-2x my-2"/>
													{organizationData.attachments.map(attachment => (
														<Row key={`attachment_${attachment.url}`} className="py-2">
															<a className="no-decoration" target="_blank" href={attachment.url}>
																{attachment.label}
															</a>
															<div className="text-right">({attachment.filesize})</div>
														</Row>
													))}
												</li>
											}
										</List>
									</ToastBody>
								</Toast>
							</Row>
						</TabPane>
						<TabPane tabId="2">
							{ (this.props.role !== roles.REVIEWER || this.state.applicationData.reviewer_uuid === this.props.user) &&
								<Row className="mb-2">
									<Col className="d-flex justify-content-end">
										<Button outline color="success" onClick={this.toggleNewMessageModal}><i className="fa fa-envelope-o mr-1"/><T>new message</T>
										</Button>
									</Col>
								</Row>
							}
							<Row>
								<Col>
									{ this.state.messages.map((message, index) => (
										<Snippet
											className={message.sender === this.props.organization ? 'cursor-pointer mt-1 mr-3' : 'cursor-pointer mt-1 ml-3'}
											key={`message_preview_${index}`}
											body={message.body}
											createdAt={message.created_at}
											attachments={message.attachments}
											read={message.read}
											onClick={() => this.handleSnippetClick(message.uuid)}
										/>
									))}
								</Col>
							</Row>
						</TabPane>
					</TabContent>
					<MessageModal isOpen={this.state.isMessageModalOpen} toggle={this.closeMessageModal} {...this.state.activeMessageContent} role={this.props.role}/>
					<NewMessageModal isOpen={this.state.isNewMessageModalOpen} toggle={this.toggleNewMessageModal} onSuccess={this.onNewMessage} application={application}/>
				</React.Fragment>
			</SecureContent>
		);
	}
}

const mapStateToProps = (state) => ({
	organization: state.ui.settings.values.organization,
	role: state.profile.user.role,
	user: state.profile.user.uuid,
	messages: state.messages.unread,
});

ApplyWrapper = connect(mapStateToProps)(ApplyWrapper);

export default ApplyWrapper;
