import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { Row, Col, Button, Table, FormText } from 'reactstrap';
import Card from '../../components/collapsingCard';
import StatusIndicator from '../../components/statusIndicator';

import { toggleModal } from 'core/ducks/ui/modal';
import Alert from 'core/views/modals/alert';
import { Form } from 'flows/views/components';
import { getData, deleteData } from 'core/ducks/update';
import { uploadData } from 'core/ducks/upload';
import T from 'modules/i18n';

class OrgDetails extends Component {

	constructor(props) {
		super(props);
		this.actions = bindActionCreators({toggleModal}, props.dispatch);
		let disabled = false;
		let locality;
		if (this.props.match.params.identifier) {
			const { identifier } = this.props.match.params;
			this.endpoints = Object.entries(this.endpoints)
				.map(([form, endpoint]) => [form, `${endpoint}/${form==='beneficiary_details' ? 'identifier' : 'organization'}/${identifier}`])
				.reduce((obj, [form, endpoint]) => ({...obj, [form]: endpoint}), {});
			disabled = true;
			locality = identifier.startsWith('EL') ? 'domestic' : 'foreign';
		} else {
			locality = this.props.locality;
		}
		this.endpoints = {
			[locality === 'domestic' ? 'beneficiary_details' : 'foreign_beneficiary']: 'app/organization',
			[locality === 'domestic' ? 'beneficiary_properties' : 'foreign_beneficiary_properties']: 'app/organizationProperties',
		};
		// if (locality === 'foreign')
		// 	this.endpoints.foreign_representation = 'app/organizationProperties';
		this.endpoints.legal_representative = 'app/representative';
		this.endpoints.member_addition = 'app/member/role/administrator';
		this.forms = Object.keys(this.endpoints);
		this.state = {
			values: {},
			valuesToHide: [],
			readOnly: {
				beneficiary_details: ['vat_number', 'title', 'email'],
				foreign_beneficiary: ['vat_number', 'title', 'email', 'country'],
				// beneficiary_properties: [],
				// foreign_beneficiary_properties: [],
				// foreign_representation: [],
				legal_representative: [],
				member_addition: ['firstname', 'lastname', 'email', 'phonenumber'],
			},
			hasAccepted: this.forms.reduce((obj, form) => ({...obj, [form]: false}), {}),
			disabled,
			initialAdmin: null,
			disableMemberSubmit: true,
			proxies: [],
			ready: -4,
		};
	}

	componentDidMount() {
		Object.entries(this.endpoints).forEach(([form, endpoint]) => {			
			if (endpoint === '') return;
			this.props.dispatch(getData(endpoint)).then(values => {
				let initialAdmin;
				// if (form === 'beneficiary_properties')
				// 	values.discount = (values.discount !== 0);
				if (form === 'member_addition') {
					initialAdmin = values.vat_number = values.identifier;
				}
				let other = {};
				// if (form === 'foreign_beneficiary_properties') {
				// 	const { representation_certificate } = values;
				// 	other = {foreign_representation: {representation_certificate}};
				// }
				this.setState({
					values: {
						...this.state.values,
						[form]: values,
						...other,
					},
					initialAdmin: initialAdmin ? initialAdmin : this.state.initialAdmin,
					ready: this.state.ready + 1,
				});
			});
		});
		this.loadProxyData();
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.shouldUpdateProxyData && this.props.shouldUpdateProxyData)
			this.loadProxyData();
	}

	loadProxyData = () => {
		this.props.dispatch( getData('app/member/role/proxy') )
			.then((proxies) => {
				this.setState({proxies});
				this.props.setShouldUpdateProxyData();
			});
	}

	handleAcceptanceChange = (scope, hasAccepted) => {
		this.setState({
			hasAccepted: {...this.state.hasAccepted, [scope]: hasAccepted}
		});
	}

	handleValidationChange = (scope, target) => {
		if (scope === 'member_addition' &&  Object.keys(target).includes('vat_number')) {
			const readOnly = target['vat_number'] ? [] : ['firstname', 'lastname', 'email', 'phonenumber'];
			this.setState({
				readOnly: {...this.state.readOnly, member_addition: readOnly},
				disableMemberSubmit: !target['vat_number']
			});
		}
	}

	handleSubmit = (scope, data) => {
		if (this.state.disabled) return;
		this.props.dispatch(uploadData(this.endpoints[scope], data, 'put'));
	}

	handleProxyDelete = ({identifier}) => {
		this.props.dispatch(deleteData(`app/member/identifier/${identifier}/role/proxy`))
			.then(() => this.loadProxyData());
	}

	render() {
		const { hasAccepted, disableMemberSubmit } = this.state;
		if (this.state.ready < 0) return null;
		return (
			<React.Fragment>
				{ this.forms.map(form => {
					return form !== 'beneficiary_properties' && <Card
						key={`administration_form_${form}`}
						title={this.props.i18n[form==='member_addition' ? 'admin' : form]}
						className="m-2 p-2 w-md-75"
						initiallyOpen={!this.props.location.state || this.props.location.state.includes(form)}
					>
						{ (form === 'member_addition' && this.state.values['member_addition']) &&
							<Row>
								<Col className="d-flex justify-content-between">
									<div className="d-inline-block w-75 text-justify">
										<FormText><T>administrator form message</T></FormText>
										{
											// <FormText><b><T>note</T></b>: <T>administrator form note</T></FormText>
											// <FormText><T>administrator form post</T></FormText>
										}
									</div>
									<div className="d-inline-block text-right pr-4"><StatusIndicator status={this.state.values['member_addition'].accepted}/></div>
								</Col>
							</Row>
						}
						<Form
							form={form}
							values={this.state.values[form]}
							onSubmit={(data) => this.handleSubmit(form, data)}
							readOnly={this.state.readOnly[form]}
							onValidationChange={(target) => this.handleValidationChange(form, target)}
							onAcceptanceChange={(hasAccepted) => this.handleAcceptanceChange(form, hasAccepted)}
							disabled={this.state.disabled ? true : (form === 'member_addition' ? !this.props.canUpdateAdmin : false)}
							urlPrefix="app"
						>
							<Row>
								<Col className="text-right">
									<Button color="success" disabled={!hasAccepted[form] || (form==='member_addition' && disableMemberSubmit)}><T>update</T></Button>
								</Col>
							</Row>
						</Form>
					</Card>
				})}
				{ this.props.hasProxies &&
					<Card title={this.props.i18n.proxies} className="m-2 p-2 w-md-75" initiallyOpen={!this.props.location.state || this.props.location.state.includes('proxy')}>
						<Table responsive>
							<thead>
								<tr>
									{['', 'fullname', 'vat number', 'email', 'phone number', ''].map(((title, index) => (<th key={`table-header-${index}`}><T>{title}</T></th>)))}
								</tr>
							</thead>
							<tbody>
								{ this.state.proxies.map(proxy => (
									<tr key={`table-proxy-row-${proxy.identifier}`}>
										<td>
											{ !this.state.disabled &&
													<i role="button" className="fa fa-trash-o text-danger" onClick={
														() => this.actions.toggleModal(true,
															<Alert
																toggle={this.actions.toggleModal}
																title="drop confirm"
																message="do you wish to continue"
																onConfirm={() => this.handleProxyDelete(proxy)}
															/>
														)}
													/>
											}
										</td>
										<td>{proxy.fullname}</td>
										<td>{proxy.identifier}</td>
										<td><a href={`mailto: ${proxy.email}`}>{proxy.email}</a></td>
										<td><a href={`tel: ${proxy.phonenumber}`}>{proxy.phonenumber}</a></td>
										<td><StatusIndicator status={proxy.accepted}/></td>
									</tr>
								))}
							</tbody>
						</Table>
						{ !this.state.disabled &&
							<Row>
								<Col className="d-flex justify-content-end">
									<Button onClick={this.props.handleAddProxy} className="mr-2" color="info">
										<T>add proxy</T>
									</Button>
								</Col>
							</Row>
						}
					</Card>
				}
			</React.Fragment>
		);
	}
}

export default OrgDetails;
