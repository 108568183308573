import React from 'react';
import { Collapse } from 'reactstrap';
import FormElement from './formElement';
import PropTypes from 'prop-types';

const determineIfOpen = (cond, values) => {
	const symbols = ['<=', '>=', '<', '>', '==', '!='];
	let operator;
	symbols.forEach(symbol => {
		if (cond.includes(symbol)) {
			operator = symbol;
			return;
		}
	});
	if (!operator)
		return false;
	const [formfield, value] = cond.split(operator);
	const field = formfield.split('.')[1];
	if (!Object.keys(values).includes(field))
		return true;
	switch (operator) {
		case '<=':
			return values[field] <= value;
		case '>=':
			return values[field] >= value;
		case '<':
			return values[field] < value;
		case '>':
			return values[field] > value;
		case '==':
			return values[field] === value;
		case '!=':
			return values[field] !== value;
		default:
			return true;
	}
}

const FormContent = ({fields, values, onChange, onHide, onShow, onValidationChange, urlPrefix, prevValues, disabled, hidden=false}) => {
	let content = [];
	fields.forEach(field => {
		const { children } = field;
		if (field.condition) {
			content.push(
				<Collapse
					key={`form_group_${field.name}`}
					isOpen={determineIfOpen(field.condition, values)}
					className="pl-2"
					onExiting={() => onHide([field.name])}
					onEntering={() => onShow([field.name])}
				>
					<FormElement
						field={field}
						value={values[field.name]}
						onChange={onChange}
						onValidationChange={onValidationChange}
						hidden={!determineIfOpen(field.condition, values)}
						urlPrefix={urlPrefix}
						prevValue={prevValues[field.name]}
					/>
				</Collapse>
			);
		} else {
			content.push(
				<FormElement
					key={`form_group_${field.name}`}
					field={field}
					value={values[field.name]}
					onChange={onChange}
					onValidationChange={onValidationChange}
					hidden={disabled}
					urlPrefix={urlPrefix}
					prevValue={prevValues[field.name]}
				/>
			);
		}
		if (children) {
			const isOpen = (typeof values[field.name] === 'string' || values[field.name] instanceof String) ? values[field.name] === 'true' : values[field.name]
			content.push(
				<Collapse
					key={`form_group_${field.name}_children`}
					isOpen={isOpen}
					className="pl-2 border-bottom"
					onExiting={() => onHide(children.map(field => field.name))}
					onEntering={() => onShow(children.map(field => field.name))}
				>
					<FormContent
						fields={children}
						values={values}
						onChange={onChange}
						onHide={onHide}
						onShow={onShow}
						hidden={!isOpen}
						urlPrefix={urlPrefix}
						onValidationChange={onValidationChange}
						prevValues={prevValues}
					/>
				</Collapse>
			);
		}
	});

	return content;
}

FormContent.propTypes = {
	fields: PropTypes.array.isRequired,
	values: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	onHide: PropTypes.func.isRequired,
	onShow: PropTypes.func.isRequired,
	onValidationChange: PropTypes.func,
	urlPrefix: PropTypes.string.isRequired,
};

export default FormContent;
