import React, { Component } from 'react';
import { Button } from 'reactstrap';

import {
	AdministratorSVG, ApplySVG, BusinessSVG, EditDetailsSVG, ForeignSVG, InvitationsSVG,
	ObjectionSVG, PendingApplicationsSVG, ProxySVG,
} from '../img';

const SVG = ({dimension, action, fill="var(--cui-info)", className="d-block p-3"}) => {
	const style = dimension===0 ? { width: 'auto', height: '100%' } : { width: dimension + 'px', height: dimension + 'px'}
	const props = {style, fill, className};
	switch (action) {
		case 'apply':
			return <ApplySVG {...props}/>
		case 'initiate':
			return <BusinessSVG {...props}/>
		case 'editDetails':
			return <EditDetailsSVG {...props}/>
		case 'foreign':
			return <ForeignSVG {...props}/>
		case 'invitations':
			return <InvitationsSVG {...props}/>
		case 'objection':
			return <ObjectionSVG {...props}/>
		case 'pending':
			return <PendingApplicationsSVG {...props}/>
		case 'proxy':
			return <ProxySVG {...props}/>
		case 'admin':
			return <AdministratorSVG {...props}/>
		default:
			return null;
	}
}

class Actions extends Component {
	constructor(props) {
		super(props);
		this.state = {
			fill: "var(--cui-info)",
			hover: false,
			dimension: 0,
		};
		this.ref = React.createRef();
	}

	componentDidMount() {
		if (this.ref.current) {
			setTimeout(() => {
				if (this.ref.current) {
					const dimension = Math.max(this.state.dimension, this.ref.current.scrollHeight);
					this.setState({dimension});
				}
			}, 0);
		}
	}

	mouseEvent = (e) => {
		this.setState({
			fill: e === 'enter' ? "var(--cui-warning)" : "var(--cui-info)",
			hover: e === 'enter',
		});
	}

	render() {
		const { text, placeholders, children, onClick, ...otherProps } = this.props;
		return (
			<div ref={this.ref}>
				<Button
					color="light"
					className="mx-2 h-100 action-button"
					onMouseEnter={() => this.mouseEvent('enter')}
					onMouseLeave={() => this.mouseEvent('leave')}
					onClick={onClick}
				>
					<SVG dimension={this.state.dimension} {...otherProps} fill={this.state.fill}/>
					<span className={`action-text fw-semibold ${this.state.hover ? '' : 'text-info'}`}>
						{children}
					</span>
				</Button>
			</div>
		);
	}
}

export default Actions;
