import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
	Button, Card, CardBody, CardText, CardHeader, Form, FormText, Row, Col, Collapse,
	Input, InputGroup, InputGroupAddon, InputGroupText, FormGroup, Label,
} from 'reactstrap';

import { toggleModal } from 'core/ducks/ui/modal';
import Alert from 'core/views/modals/alert';
import { clearState, deleteData, postData, updateData, getData } from 'core/ducks/update';
import { requestData } from 'core/ducks/list';
import { initContext, setContext } from 'core/ducks/context';
import { Loading } from 'core/components';
import { DragDrop } from 'sequence';
import { fieldTypes } from 'flows/model/constants';
import { characterConverter } from 'core/model/lib';
import ForeignConditionInput from '../components/foreignConditionInput';
import T from 'modules/i18n';

class EditForms extends Component {

	constructor(props) {
		super(props);
		this.state = {
			items: [],
			fields: [],
			fieldsUpdated: false,
			modals: null,
			isForeignConditionEnabled: {},
		};

		this.id = 0;

		this.actions = bindActionCreators({toggleModal}, props.dispatch);
		this.lastOptionRef = React.createRef();

		this.specialKeys = {
			attachment: ['acceptedFileTypes', 'maxFileSize'],
			parse_pdf: ['maxFileSize'],
			application: ['isMulti', 'workflow'],
			category: ['categoryID']
		};

		this.openInput = this.openInput.bind(this);
		this.closeInput = this.closeInput.bind(this);
		this.toggleFieldWindow = this.toggleFieldWindow.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleLabelFormSubmit = this.handleLabelFormSubmit.bind(this);
		this.handleLabelFormReset = this.handleLabelFormReset.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.addField = this.addField.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleSubmitDetails = this.handleSubmitDetails.bind(this);
		this.onDragEnd = this.onDragEnd.bind(this);
	}

	openInput(index) {
		this.handleInputChange({target: {name: 'isInputOpen', value: true}}, index);
	}

	closeInput(index) {
		this.handleInputChange({target: {name: 'isInputOpen', value: false}}, index);
	}

	toggleFieldWindow(index) {
		let { items } = this.state;
		this.handleInputChange({target: {name: 'isWindowOpen', value: !items[index].isWindowOpen}}, index);
	}

	handleDelete(index) {
		let item = this.state.items[index];
		if (item.protected)
			return;
		if (item.id < 0) {
			this.setState({
				items: [...this.state.items.slice(0, index), ...this.state.items.slice(index+1)]
			});
		} else {
			let url = `admin/forms/form/${this.props.match.params.form}/id/${item.id}`;
			this.props.dispatch( deleteData(url) ).then(() => {
				this.setState({
					items: [...this.state.items.slice(0, index), ...this.state.items.slice(index+1)]
				});
			});
		}
	}

	handleInputChange(event, index, options_index=null) {
		const { items } = this.state;
		const target = event.target;
		let value = target.type === 'checkbox' ? target.checked : target.value;
		let item;

		if (target.name === 'options') {
			const type = target.getAttribute('data-type');
			if (options_index === null) {
				if (value === '' || type !== 'keys')
					return;
				items[index].options.values = [...items[index].options.values, ''];
				options_index = !options_index ? items[index].options.keys.length : options_index;
				target.value = '';
			}
			item = {
				...items[index],
				options: {
					...items[index].options,
					[type]: (value!== '' || ['acceptedFileTypes', 'maxFileSize'].includes(items[index].options.keys[options_index])) ?
						[
							...items[index].options[type].slice(0, options_index),
							value,
							...items[index].options[type].slice(options_index + 1)
						]
						:
						[
							...items[index].options[type].slice(0, options_index),
							...items[index].options[type].slice(options_index + 1)
						]
				}
			};
		} else if (target.name === 'type' && Object.keys(this.specialKeys).includes(value)) {
			item = {
				...items[index],
				options: {keys: this.specialKeys[value], values: [items[index].options.values[0] || '', items[index].options.values[1] || ''] },
				[target.name]: value
			};
		} else {
			if (target.name === 'name') {
				value = value.toLowerCase();
				value = value.split(' ').join('_');
				value = value.split('-').join('_');
				value = characterConverter(value);
				if (!/^([a-zA-Z0-9_]*)$/.test(value))
					return;
			}
			item = {...items[index], [target.name]: value};
		}
		this.setState({
			items: [...items.slice(0, index), item, ...items.slice(index + 1)]
		}, () => {
			if (target.name === 'options' && this.lastOptionRef.current)
				this.lastOptionRef.current.focus();
		});
	}

	handleLabelFormSubmit(event, index) {
		event.preventDefault();
		let path = `admin/forms/form/${this.props.match.params.form}/id/${this.state.items[index].id}`;
		this.props.dispatch(updateData(path, {label: this.state.items[index].label})).then(() => {
			this.initialItems[index].label = this.state.items[index].label;
			this.closeInput(index);
		});
	}

	handleLabelFormReset(event, index) {
		let items = this.state.items;
		items[index].label = this.initialItems[index].label;
		this.setState({items}, () => {
			this.closeInput(index);
		});
	}

	addField() {
		this.id -= 1;
		const { items } = this.state;
		this.setState({
			items: [
				...items,
				{
					id: this.id, label: '', name: '', type: 'string', options: {keys: [], values: []},
					description: '', is_required: false, min_size: '', max_size: '', validation: '',
					validation_msg: '', sequence: items.length + 1, isInputOpen: true, isWindowOpen: true,
				}
			],
		});
	}

	handleSubmit() {
		const items = this.state.items
			.map((item, sequence) => [[item.name], sequence])
			.reduce((obj, elem) => ({
				...obj,
				[elem[0]]: elem[1]
			}), {});
		this.props.dispatch( updateData(`admin/forms/form/${this.props.match.params.form}`, items) );
	}

	handleSubmitDetails(event, index) {
		event.preventDefault();
		let data = Object.assign({}, this.state.items[index]);
		const id = data.id;
		delete data.isInputOpen;
		delete data.isWindowOpen;
		let method;
		if (['text', 'string', 'number', 'plaintext'].includes(data.type))
			data.options = null;
		if (id > 0) {
			method = 'put';
			delete data.sequence;
			for (let field in data) {
				if (data[field] === this.initialItems[index][field])
					delete data[field];
			}
		} else {
			method = 'post';
			delete data.id;
		}
		const nullable = ['min_size', 'max_size', 'validation', 'validation_msg']
		for (let i = nullable.length - 1; i >= 0; i--) {
			let field = nullable[i];
			if (data[field] === '')
				data[field] = null;
		}
		this.closeInput(index);
		let path = `admin/forms/form/${this.props.match.params.form}`;
		if (method==='post') {
			this.props.dispatch( postData(path, data) );
		} else {
			this.props.dispatch( updateData(`${path}/id/${id}`, data) );
		}
	}

	onDragEnd(items) {
		this.setState({items});
	}

	setContext = (newContext) => {
		this.setState({...newContext});
	}

	componentDidMount() {
		this.props.dispatch( requestData('forms', 'admin/forms') );
		this.props.dispatch(initContext({form: this.props.match.params.form, set: this.setContext}));
		this.props.dispatch(getData('flows/modal/with_content/')).then(modals => this.setState({modals}));
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.match.params.form !== this.props.match.params.form)
			this.props.dispatch( setContext({form: this.props.match.params.form}) );
		if (!prevState.fieldsUpdated && this.state.fieldsUpdated) {
			const { fields } = this.state;
			this.initialItems = Object.keys(fields).map((sequence) => {
				let field = fields[sequence];
				let options = fields[sequence].options
					? JSON.parse(fields[sequence].options)
					: {};
				let values = [];
				let keys = Object.keys(this.specialKeys).includes(field.type)
					? this.specialKeys[field.type]
					: Object.keys(options);

				Object.keys(options).forEach((key) => {
					values.push(options[key]);
				});
				Object.keys(field).forEach((key) => {
					if (!field[key])
						field[key] = '';
				});
				return {...field, sequence, options: {keys, values}, isInputOpen: false, isWindowOpen: false};
			});
			this.setState({items: [...this.initialItems], fieldsUpdated: false});
		}
	}

	componentWillUnmount() {
		this.props.dispatch(clearState());
	}

	render() {
		const { pending, i18n } = this.props;
		const { items } = this.state;
		if (!this.props.match.params.form)
			return (<Card className="mt-4"><CardBody><CardText><T>choose a form</T></CardText></CardBody></Card>);
		if ( pending || typeof items.map != 'function' )
			return (<Loading />);

		return (
			<div className="mt-4">
				<Row className={!this.state.fields['1'] ? 'd-none' : 'my-2'}>
					<Col className="text-right py-0">
						<Button className="mx-2" type="button" color="success" onClick={this.handleSubmit}>
							<T>save structure</T>
						</Button>
					</Col>
				</Row>
				<DragDrop onDragEnd={this.onDragEnd} items={items}>
					{ items.map((field, index) => (
						<Card key={field.id} id={field.id} className="mb-0">
							<CardHeader className="pr-1">
								<Row className="m-0 p-0">
									<Col xs="11" className="m-0 p-0">
										<span
											className={field.isInputOpen ? 'd-none' : undefined}
											onDoubleClick={() => this.openInput(index)}
											title="Double click to edit"
										>
											{field.label}
										</span>
										{ field.isInputOpen && (
											<Form
												onSubmit={(event) => this.handleLabelFormSubmit(event, index)}
												onReset={(event) => this.handleLabelFormReset(event, index)}
											>
												<FormGroup className="mb-0">
													<InputGroup>
														<Input
															type="text"
															name="label"
															value={field.label}
															placeholder="Label"
															required
															onChange={(event) => this.handleInputChange(event, index)}
														/>
														<InputGroupAddon addonType="append">
															<InputGroupText className="p-0">
																<button disabled={this.initialItems[index]===undefined} type="reset" title="Close" className="text-warning btn m-0">
																	<i className="fa fa-ban m-0"/>
																</button>
															</InputGroupText>
														</InputGroupAddon>
														<InputGroupAddon addonType="append">
															<InputGroupText className="p-0">
																<button disabled={this.initialItems[index]===undefined} type="submit" title="Save" className="text-success btn m-0">
																	<i className="fa fa-floppy-o m-0"/>
																</button>
															</InputGroupText>
														</InputGroupAddon>
													</InputGroup>
												</FormGroup>
											</Form>
										)}
									</Col>
									<Col xs="1" className="m-0 p-0 text-right">
										<i
											className={field.isWindowOpen ? 'fa fa-minus round' : 'fa fa-plus round'}
											role="button"
											title={field.isWindowOpen ? i18n.minimize : i18n.maximize}
											onClick={() => this.toggleFieldWindow(index)}
										/>
										<i
											className={field.protected ? 'fa fa-times round disabled' : 'fa fa-times round'}
											role="button"
											title={i18n.delete}
											onClick={
												() => this.actions.toggleModal(true,
													<Alert
														toggle={this.actions.toggleModal}
														title="drop confirm"
														message="do you wish to continue"
														onConfirm={() => this.handleDelete(index)}
													/>
												)
											}
										/>
									</Col>
								</Row>
							</CardHeader>
							<Collapse isOpen={field.isWindowOpen}>
								<CardBody>
									<Form onSubmit={(e) => this.handleSubmitDetails(e, index)}>
										<Row>
											<Col>
												<FormGroup>
													<InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
																<Label htmlFor={`name_${index}`} className="m-0">Name</Label>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															id={`name_${index}`}
															type="text"
															name="name"
															value={field.name}
															onChange={(event) => this.handleInputChange(event, index)}
															readOnly={field.protected}
														/>
													</InputGroup>
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col md="6" lg="3">
												<FormGroup>
													<InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
																<Label htmlFor={`type_${index}`} className="m-0">Type</Label>
															</InputGroupText>
														</InputGroupAddon>
														{ field.protected ?
															<Input id={`type_${index}`} name="type" value={field.type} readOnly={true}/>
															:
															<Input
																id={`type_${index}`}
																type="select"
																name="type"
																value={field.type}
																onChange={(event) => this.handleInputChange(event, index)}
															>
																{ fieldTypes.map((value) =>
																	<option key={`option_${index}_${value}`} value={value}>{value}</option>
																) }
															</Input>
														}
													</InputGroup>
												</FormGroup>
											</Col>
											<Col md="6" lg="9" className="justify-content-end">
												<FormGroup>
													<InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
																<Label htmlFor={`required_${index}`} className="m-0">Required</Label>
															</InputGroupText>
														</InputGroupAddon>
														<InputGroupAddon addonType="append">
															<InputGroupText>
																<Input
																	id={`required_${index}`}
																	addon
																	type="checkbox"
																	name="is_required"
																	value={field.is_required}
																	checked={field.is_required}
																	onChange={(event) => this.handleInputChange(event, index)}
																/>
															</InputGroupText>
														</InputGroupAddon>
													</InputGroup>
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col>
												{ ['map', 'radio', 'select', 'checkbox'].includes(field.type) &&
													<FormGroup tag="fieldset" className="w-100 border px-2 py-3 mr-3">
														<legend className="w-auto d-inline-block text-inherit text-muted mb-0">Options</legend>
														{field.options.keys.map((key, options_index) =>
															<FormGroup key={`key_${index}_${options_index}`}>
																<InputGroup>
																	<InputGroupAddon addonType="prepend">
																		<InputGroupText>key</InputGroupText>
																	</InputGroupAddon>
																	<Input
																		type="text"
																		name="options"
																		value={key}
																		data-type="keys"
																		onChange={(event) => this.handleInputChange(event, index, options_index)}
																	/>
																	<InputGroupAddon addonType="prepend">
																		<InputGroupText>value</InputGroupText>
																	</InputGroupAddon>
																	<input
																		className="form-control"
																		type="text"
																		name="options"
																		value={field.options.values[options_index]}
																		data-type="values"
																		data-index={options_index}
																		data-length={field.options.values.length}
																		ref={options_index===field.options.values.length-1 ? this.lastOptionRef : undefined}
																		onChange={(event) => this.handleInputChange(event, index, options_index)}
																	/>
																</InputGroup>
															</FormGroup>
														)}
														<FormGroup>
															<InputGroup>
																<InputGroupAddon addonType="prepend">
																	<InputGroupText>key</InputGroupText>
																</InputGroupAddon>
																<Input
																	type="text"
																	placeholder="new key"
																	data-type="keys"
																	name="options"
																	onBlur={(event) => this.handleInputChange(event, index)}
																/>
																<InputGroupAddon addonType="prepend">
																	<InputGroupText>value</InputGroupText>
																</InputGroupAddon>
																<Input
																	readOnly
																/>
															</InputGroup>
														</FormGroup>
													</FormGroup>
												}
												{ ['attachment', 'parse_pdf'].includes(field.type) &&
													<FormGroup tag="fieldset" className="w-100 border px-2 py-3 mr-3">
														<legend className="w-auto d-inline-block text-inherit text-muted mb-0">Options</legend>
														{ field.options.keys.map((key, options_index) =>
															<FormGroup key={`key_${index}_${options_index}`}>
																<InputGroup>
																	<InputGroupAddon addonType="prepend">
																		<InputGroupText>{key}</InputGroupText>
																	</InputGroupAddon>
																	<Input
																		type="text"
																		name="options"
																		value={field.options.values[options_index]}
																		data-type="values"
																		onChange={(event) => this.handleInputChange(event, index, options_index)}
																	/>
																</InputGroup>
															</FormGroup>
														)}
														<Col sm="3">
															<FormGroup>
																<InputGroup>
																	<InputGroupAddon addonType="prepend">
																		<InputGroupText>
																			<Label htmlFor={`required_${index}`} className="m-0">Required</Label>
																		</InputGroupText>
																	</InputGroupAddon>
																	<InputGroupAddon addonType="append">
																		<InputGroupText>
																			<Input
																				id={`required_${index}`}
																				addon
																				type="checkbox"
																				name="is_required"
																				value={field.is_required}
																				checked={field.is_required}
																				onChange={(event) => this.handleInputChange(event, index)}
																			/>
																		</InputGroupText>
																	</InputGroupAddon>
																</InputGroup>
															</FormGroup>
														</Col>
													</FormGroup>
												}
												{['application'].includes(field.type) &&
													<FormGroup tag="fieldset" className="w-100 border px-2 py-3 mr-3">
														<legend className="w-auto d-inline-block text-inherit text-muted mb-0">Options</legend>
														{ field.options.keys.map((key, options_index) =>
															<FormGroup key={`key_${index}_${options_index}`}>
																<InputGroup>
																	<InputGroupAddon addonType="prepend">
																		<InputGroupText>
																			<Label htmlFor={`required_${index}`} className="m-0"><T>{key}</T></Label>
																		</InputGroupText>
																	</InputGroupAddon>
																	<InputGroupAddon addonType="append">
																		<InputGroupText>
																			<Input
																				type={key === 'workflow' ? 'text' : 'checkbox'}
																				addon
																				name="options"
																				value={field.options.values[options_index]}
																				checked={field.options.values[options_index] === '' ? false : field.options.values[options_index]}
																				data-type="values"
																				onChange={(event) => this.handleInputChange(event, index, options_index)}
																			/>
																		</InputGroupText>
																	</InputGroupAddon>
																</InputGroup>
															</FormGroup>
														)}
													</FormGroup>
												}
												{['category'].includes(field.type) &&
													<FormGroup tag="fieldset" className="w-100 border px-2 py-3 mr-3">
														<legend className="w-auto d-inline-block text-inherit text-muted mb-0">Options</legend>
														{ field.options.keys.map((key, options_index) =>
															<FormGroup key={`key_${index}_${options_index}`}>
																<InputGroup>
																	<InputGroupAddon addonType="prepend">
																		<InputGroupText>
																			<Label htmlFor={`required_${index}`} className="m-0"><T>{key}</T></Label>
																		</InputGroupText>
																	</InputGroupAddon>
																	<InputGroupAddon addonType="append">
																		<InputGroupText>
																			<Input
																				type={'text'}
																				addon
																				name="options"
																				value={field.options.values[options_index]}
																				checked={field.options.values[options_index] === '' ? false : field.options.values[options_index]}
																				data-type="values"
																				onChange={(event) => this.handleInputChange(event, index, options_index)}
																			/>
																		</InputGroupText>
																	</InputGroupAddon>
																</InputGroup>
															</FormGroup>
														)}
													</FormGroup>
												}
											</Col>
										</Row>
										{ !['end-conditional'].includes(field.type) &&
											<FormGroup row>
												<Col>
													<InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
																<Label htmlFor={`description_${index}`} className="m-0">Description</Label>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															type="text"
															name="description"
															value={field.description}
															onChange={(event) => this.handleInputChange(event, index)}
														/>
													</InputGroup>
												</Col>
											</FormGroup>
										}
										{ !['category', 'application', 'attachment', 'parse_pdf', 'conditional', 'end-conditional', 'email', 'vat_number', 'accept', 'country'].includes(field.type) &&
											<FormGroup tag="fieldset" className="w-100 border px-2 pb-3 mr-3 mt-2">
												<legend className="w-auto d-inline-block text-inherit text-muted mb-0">Validation</legend>
												<FormGroup row className="justify-content-end">
													<Col md="12" lg="6" xl="4" className="mt-2 mt-lg-none">
														<InputGroup>
															<InputGroupAddon addonType="prepend">
																<InputGroupText>
																	<Label htmlFor={`min_size_${index}`} className="m-0">Minimum size</Label>
																</InputGroupText>
															</InputGroupAddon>
															<Input
																type="number"
																min="0"
																id={`min_size_${index}`}
																name="min_size"
																value={field.min_size}
																onChange={(event) => this.handleInputChange(event, index)}
															/>
														</InputGroup>
													</Col>
													<Col md="12" lg="6" xl="4" className="mt-2 mt-lg-none">
														<InputGroup>
															<InputGroupAddon addonType="prepend">
																<InputGroupText>
																	<Label htmlFor={`max_size_${index}`} className="m-0">Maximum size</Label>
																</InputGroupText>
															</InputGroupAddon>
															<Input
																type="number"
																min="0"
																id={`max_size_${index}`}
																name="max_size"
																value={field.max_size}
																onChange={(event) => this.handleInputChange(event, index)}
															/>
														</InputGroup>
													</Col>
												</FormGroup>
												<FormGroup row>
													<Col md="12" lg="6" className="mt-2 mt-lg-none">
														<InputGroup>
															<InputGroupAddon addonType="prepend">
																<InputGroupText>
																	<Label htmlFor={`validation_${index}`} className="m-0">Validation rules</Label>
																</InputGroupText>
															</InputGroupAddon>
															<Input
																type="text"
																id={`validation_${index}`}
																name="validation"
																value={field.validation}
																onChange={(event) => this.handleInputChange(event, index)}
															/>
														</InputGroup>
													</Col>
													<Col md="12" lg="6" className="mt-2 mt-lg-none">
														<InputGroup>
															<InputGroupAddon addonType="prepend">
																<InputGroupText>
																	<Label htmlFor={`validation_msg_${index}`} className="m-0">Validation message</Label>
																</InputGroupText>
															</InputGroupAddon>
															<Input
																type="text"
																id={`validation_mesg_${index}`}
																name="validation_msg"
																value={field.validation_msg}
																onChange={(event) => this.handleInputChange(event, index)}
															/>
														</InputGroup>
													</Col>
												</FormGroup>
											</FormGroup>
										}
										{ !['end-conditional'].includes(field.type) &&
											<>
												<Row className="w-100 p-2 m-2">
													<Col>
														<FormGroup>
															<Label check>
																<Input
																	type="checkbox"
																	id={`modal_enabled_${index}`}
																	name="is_modal_enabled"
																	value={field.is_modal_enabled}
																	checked={field.is_modal_enabled}
																	onChange={(event) => this.handleInputChange(event, index)}
																/>
																Include modal
															</Label>
														</FormGroup>
														<Collapse isOpen={field.is_modal_enabled === true ? field.is_modal_enabled : false}>
															<Row>
																<Col xl="6">
																	<FormText>
																		Include text inside [[...]] in <b>label</b> field to trigger the modal.
																	</FormText>
																	<FormGroup>
																		<Label>
																			Modal
																			<Input
																				id={`modal_${index}`}
																				type="select"
																				name="modal"
																				value={field.modal}
																				onChange={(event) => this.handleInputChange(event, index)}
																			>
																				<option>Select modal</option>
																				{ Object.entries(this.state.modals).map(([uuid, {name, content}]) => (
																					<option key={`option_edit_forms_${uuid}_${name}`} value={uuid}>{name}</option>
																				))}
																			</Input>
																		</Label>
																	</FormGroup>
																</Col>
																<Col xl="6">
																	{ field.modal &&
																		<FormText className="border p-3" dangerouslySetInnerHTML={{__html: this.state.modals[field.modal].content}}/>
																	}
																</Col>
															</Row>
														</Collapse>
													</Col>
												</Row>
												<Row className="w-100 p-2 m-2">
													<Col>
														<ForeignConditionInput
															forms={Object.keys(this.props.forms)}
															name="condition"
															value={field.condition}
															onChange={(event) => this.handleInputChange(event, index)}
														/>
													</Col>
												</Row>
											</>
										}
										<Row>
											<Col className="text-right">
												<Button type="submit" color="success"><i className="fa fa-floppy-o"/></Button>
											</Col>
										</Row>
									</Form>
								</CardBody>
							</Collapse>
						</Card>
					))}
				</DragDrop>
				<Row className="my-2">
					<Col className="text-right py-0">
						<Button type="button" className="mx-2" onClick={this.addField}><T>add</T></Button>
						<Button
							type="button"
							color="success"
							className={!this.state.fields['1'] ? 'd-none' : 'mx-2'}
							onClick={this.handleSubmit}
						>
							<T>save structure</T>
						</Button>
					</Col>
				</Row>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	i18n: state.i18n.messages,
	pending: state.update.pending,
	forms: state.list.forms.data,
});

EditForms = connect(mapStateToProps)(EditForms);

export default EditForms;
