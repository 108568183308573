import React, { useState, useEffect } from "react";
import { Row, Col, Button } from 'reactstrap';

import { AsyncPaginate } from "input-fields";
import T from 'modules/i18n';

const CustomCategory = ({ value, setValue }) => {
  return (
    <React.Fragment>
      <Row>
        <Col><T>category not found</T></Col>
      </Row>
    </React.Fragment>
  );
}

const CategoryElement = ({ name, value, className, disabled, readOnly, required, loadOptions, loadSubOptions, isMulti, onChange }) => {
  const [categoryValue, setCategoryValue] = useState((!value || value === '') ? '' : (isMulti ? value.split(';').map(e => ({ value: e, label: e })) : value));
  const [enteredValue, setEnteredValue] = useState('');

  const [subCategoryValue, setSubCategoryValue] = useState((!value || value === '') ? '' : (isMulti ? value.split(';').map(e => ({ value: e, label: e })) : { value: value.subValue, label: value.subLabel }));

  useEffect(() => {
    const value = {
      value: categoryValue && categoryValue.value || '',
      label: categoryValue && categoryValue.label || '',
      subValue: subCategoryValue && subCategoryValue.value || '',
      subLabel: subCategoryValue && subCategoryValue.label || ''
    }
    onChange({ target: { type: 'select', value, name: name } });
  }, [categoryValue, subCategoryValue]);

  const applyChange = (value) => {
    let strValue;
    if (value === null) {
      strValue = '';
    } else {
      strValue = isMulti ? value.map(e => e.value).join(';') : value.value;
    }
    // console.log(value)
    onChange({ target: { type: 'select', value, name: name } });
  }

  const handleValueSelect = (value) => {
    // const fullValue = isMulti ? [...categoryValue, value] : value;
    // setCategoryValue(fullValue);
    // setEnteredValue('');
    // applyChange(fullValue);
  };

  const handleInputChange = (inputValue, { action, prevInpuValue }) => {
    switch (action) {
      case 'input-change':
        setEnteredValue(inputValue);
        break;

      case 'input-blur':
      case 'menu-close':
        setEnteredValue('');
        break;

      default:
        break;
    }
  };

  const handleChange = (value) => {
    setCategoryValue(value);
    setSubCategoryValue('');
    // applyChange(value);
  };

  const handleSubChange = (value) => {
    setSubCategoryValue(value);
  }


  const handleMenuOpen = async () => {
    console.log('simos')
  };

  return (
    <React.Fragment>
      <AsyncPaginate
        className={className}
        name={name}
        value={categoryValue}
        loadOptions={loadOptions}
        onChange={(value) => handleChange(value)}
        isClearable={!isMulti}
        additional={{ page: 1 }}
        inputProps={{ required }}
        disabled={disabled}
        readOnly={readOnly}
        required={required}
        isMulti={isMulti}
        backspaceRemovesValue={true}
        isOptionSelected={true}
        onInputChange={handleInputChange}
        noOptionsMessage={() => (
          <CustomCategory value={enteredValue} setValue={(value) => handleValueSelect(value)} />
        )}
      />
      <br />
      <AsyncPaginate
        key={`${Math.random()}`}
        className={className}
        name={name}
        value={subCategoryValue}
        loadOptions={loadSubOptions}
        onMenuOpen={handleMenuOpen}
        onChange={(value) => handleSubChange(value)}
        isClearable={!isMulti}
        additional={{ page: 1 }}
        inputProps={{ required }}
        disabled={disabled ? true : categoryValue ? false : true}
        readOnly={readOnly}
        required={required}
        isMulti={isMulti}
        backspaceRemovesValue={true}
        isOptionSelected={true}
        onInputChange={handleInputChange}
        noOptionsMessage={() => (
          <CustomCategory value={enteredValue} setValue={(value) => handleValueSelect(value)} />
        )}
      />

    </React.Fragment>
  );
}

export default CategoryElement;
