import React, { Component } from 'react';
import {
	Row, Col, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText
} from 'reactstrap';
import PropTypes from 'prop-types';
import { MapContainer as LeafletMap, Marker, TileLayer, useMapEvents, FeatureGroup, Rectangle } from 'react-leaflet';
import proj4 from 'proj4';
import L from 'leaflet';
import VisibilitySensor from 'react-visibility-sensor';
import { EditControl } from 'react-leaflet-draw';

import T from 'modules/i18n';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
	iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
	iconUrl: require('leaflet/dist/images/marker-icon.png'),
	shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

class Map extends Component {

	constructor(props) {
		super(props);
		proj4.defs('EPSG:2100', "+proj=tmerc +lat_0=0 +lon_0=24 +k=0.9996 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=-199.87,74.79,246.62,0,0,0,0 +units=m +no_defs");
		this.precision = (props.options && props.options.EPSG && props.options.EPSG === 'EPSG:4326') ? 8 : 3;

		const map = this.initialize(props);
		this.state = {
			rule: {},
			map,
			clicked: false
		};

		this.mapRef = React.createRef();

		this.handleCoordinatesChange = this.handleCoordinatesChange.bind(this);
		this.handleNewCoordinates = this.handleNewCoordinates.bind(this);
		this.handleNewBBOXCoordinates = this.handleNewBBOXCoordinates.bind(this);
		this.handleCRSChange = this.handleCRSChange.bind(this);
		this.handleMapClick = this.handleMapClick.bind(this);
		this.initialize = this.initialize.bind(this);
		this.changeCoordinates = this.changeCoordinates.bind(this);
		this.onCreated = this.onCreated.bind(this);
		this.onDrawStart = this.onDrawStart.bind(this);
		this.onCreated = this.onCreated.bind(this);
		this.onEdited = this.onEdited.bind(this);
		this.onDeleted = this.onDeleted.bind(this);
	}

	initialize(props) {
		const crs = props.options.EPSG ? props.options.EPSG : 'EPSG:2100';
		let coordinates = props.value ? props.value.split(';') : undefined;
		let bounds = L.latLngBounds(L.latLng([0,0]), L.latLng([0,0]));
		if (coordinates && coordinates.lenght === 2) {
			coordinates[0] = parseFloat(Number.parseFloat(coordinates[0]).toFixed(this.precision));
			coordinates[1] = parseFloat(Number.parseFloat(coordinates[1]).toFixed(this.precision));
		}else if(coordinates){
			coordinates[0] = parseFloat(Number.parseFloat(coordinates[0]).toFixed(this.precision));
			coordinates[1] = parseFloat(Number.parseFloat(coordinates[1]).toFixed(this.precision));
			coordinates[2] = parseFloat(Number.parseFloat(coordinates[2]).toFixed(this.precision));
			coordinates[3] = parseFloat(Number.parseFloat(coordinates[3]).toFixed(this.precision));

			try{
				let corner1 = L.latLng(coordinates[1], coordinates[0]),
						corner2 = L.latLng(coordinates[3], coordinates[2])
				bounds = L.latLngBounds(corner1, corner2);
			}catch(err){}
			
		}
		let projected = [0, 0];
		try{
			projected = coordinates ? proj4(crs, 'EPSG:4326', [coordinates[1], coordinates[0]]) : ['', ''];
			if(coordinates && coordinates[2]){
				projected = coordinates ? proj4(crs, 'EPSG:4326', [coordinates[0] + ((coordinates[2] - coordinates[0]) / 2), coordinates[1] + ((coordinates[3] - coordinates[1]) / 2)]) : ['', ''];
			}
		}catch(err){}
		
		const position = props.value
			? [projected[1], projected[0]]
			: (props.options.position ? props.options.position : [40.3284628, 21.608216]);

			
		let map = {
			lat: coordinates ? coordinates[0] : '',
			lon: coordinates ? coordinates[1] : '',
			x: coordinates ? coordinates[1] : '',
			y: coordinates ? coordinates[0] : '',
			minLng: coordinates && coordinates[0] ? coordinates[0] : '',
			minLat: coordinates && coordinates[1] ? coordinates[1] : '',
			maxLng: coordinates && coordinates[2] ? coordinates[2] : '',
			maxLat: coordinates && coordinates[3] ? coordinates[3] : '',
			bounds,
			marker: projected,
			position,
			zoom: props.options.zoom || 6,
			crs
		};
		return map;
	}

	handleCoordinatesChange(event, cord) {
		let target = event.target;

		let value = parseFloat(Number.parseFloat(target.value).toFixed(this.precision));

		if(this.props.options.bbox === 'true'){
			const minLat = cord === 'minLat' ? value : this.state.map.minLat;
			const minLng = cord === 'minLng' ? value : this.state.map.minLng;
			const maxLat = cord === 'maxLat' ? value : this.state.map.maxLat;
			const maxLng = cord === 'maxLng' ? value : this.state.map.maxLng;
			value = this.handleNewBBOXCoordinates({ minLat, minLng, maxLat, maxLng })
	
			this.props.onChange({ target: { name: target.name, value } });
		}else{
			const lng = cord === 'lon' ? value : this.state.map.lon;
			const lat = cord === 'lat' ? value : this.state.map.lat;
			value = this.handleNewCoordinates({ lat, lng });
	
			this.props.onChange({ target: { name: target.name, value } });
		}

		
	}

	handleNewCoordinates(latlng) {
		const { crs } = this.state.map;
		let x, y, marker;
		let { lat, lng } = latlng;
		lng = parseFloat(Number.parseFloat(lng).toFixed(3));
		lat = parseFloat(Number.parseFloat(lat).toFixed(3));

		if (crs === 'EPSG:2100') {
			x = lng;
			y = lat;
			marker = (x !== '' && y !== '') ? proj4('EPSG:2100', 'EPSG:4326', [x, y]) : ['', ''];
		} else {
			[x, y] = (lng !== '' && lat !== '') ? proj4(crs, 'EPSG:2100', [lng, lat]) : ['', ''];
			x = parseFloat(Number.parseFloat(x).toFixed(3));
			y = parseFloat(Number.parseFloat(y).toFixed(3));
			marker = crs === 'EPSG:4326' ? [lng, lat] : ((lng !== '' && lat !== '') ? proj4(crs, 'EPSG:4326', [lng, lat]) : ['', '']);
		}

		this.setState({
			map: {
				...this.state.map,
				lon: lng,
				lat,
				x,
				y,
				marker
			}
		});

		return y + ';' + x;
	}

	handleCRSChange(event) {
		let target = event.target;
		let map = this.state.map;
		let lon, lat;
		let minLng, minLat, maxLng, maxLat;
		this.precision = target.value === 'EPSG:4326' ? 8 : 3;
		if (map.lon !== '' && map.lat !== '') {
			[lon, lat] = proj4(map.crs, target.value, [map.lon, map.lat]);
			lon = parseFloat(Number.parseFloat(lon).toFixed(this.precision));
			lat = parseFloat(Number.parseFloat(lat).toFixed(this.precision));
		} else {
			lon = lat = '';
		}
		if (map.minLng !== '' && map.minLat !== '' && map.maxLng !== '' && map.maxLat !== '') {
			[minLng, minLat] = proj4(map.crs, target.value, [map.minLng, map.minLat]);
			minLng = parseFloat(Number.parseFloat(minLng).toFixed(this.precision));
			minLat = parseFloat(Number.parseFloat(minLat).toFixed(this.precision));
			[maxLng, maxLat] = proj4(map.crs, target.value, [map.maxLng, map.maxLat]);
			maxLng = parseFloat(Number.parseFloat(maxLng).toFixed(this.precision));
			maxLat = parseFloat(Number.parseFloat(maxLat).toFixed(this.precision));
		} else {
			minLng = minLat = maxLng = maxLat = '';
		}
		this.setState({
			map: {
				...map,
				crs: target.value,
				lon,
				lat,
				minLng,
				minLat, 
				maxLng, 
				maxLat
			}
		});
	}

	handleMapClick(event) {
		this.setState({ clicked: true });
		this.changeCoordinates(event.latlng);
	}

	changeCoordinates(latlng) {
		if (this.props.readOnly)
			return;
		const { crs } = this.state.map;
		let { lat, lng } = latlng;
		[lng, lat] = crs !== 'EPSG:4326' ? proj4('EPSG:4326', crs, [lng, lat]) : [lng, lat];
		const value = this.handleNewCoordinates({ lat, lng });
		this.props.onChange({ target: { name: this.props.name, value, latlng } });
	}

	componentDidUpdate(prevProps) {
		if (JSON.stringify(prevProps.geoCoding) !== JSON.stringify(this.props.geoCoding)) {
			this.changeCoordinates(this.props.geoCoding);
		}
	}

	onDrawStart(e){
		this.mapRef.current.eachLayer(ly => {
			if(ly._bounds){
				this.mapRef.current.removeLayer(ly)
			}
		})
	}

	onCreated(e){
		this.setState({ clicked: true });
		if (this.props.readOnly)
			return;
		const { crs } = this.state.map;
		let minLat = e.layer._bounds.getSouthWest().lat;
		let minLng = e.layer._bounds.getSouthWest().lng;
		let maxLat = e.layer._bounds.getNorthEast().lat;
		let maxLng = e.layer._bounds.getNorthEast().lng;

		[minLng, minLat] = crs !== 'EPSG:4326' ? proj4('EPSG:4326', crs, [minLng, minLat]) : [minLng, minLat];
		[maxLng, maxLat] = crs !== 'EPSG:4326' ? proj4('EPSG:4326', crs, [maxLng, maxLat]) : [maxLng, maxLat];

		const p = {
			minLat: e.layer._bounds.getSouthWest().lat,
			minLng: e.layer._bounds.getSouthWest().lng,
		  maxLat: e.layer._bounds.getNorthEast().lat,
		  maxLng: e.layer._bounds.getNorthEast().lng,
		}

		const value = this.handleNewBBOXCoordinates({ minLat, minLng, maxLat, maxLng });
		this.props.onChange({ target: { name: this.props.name, value, p } });
	}

	handleNewBBOXCoordinates(bbox) {
		let { minLat, minLng, maxLat, maxLng } = bbox;
		minLng = parseFloat(Number.parseFloat(minLng).toFixed(3));
		minLat = parseFloat(Number.parseFloat(minLat).toFixed(3));
		maxLng = parseFloat(Number.parseFloat(maxLng).toFixed(3));
		maxLat = parseFloat(Number.parseFloat(maxLat).toFixed(3));
		const bounds = L.latLngBounds(L.latLng([minLat,minLng]), L.latLng([maxLat,maxLng]));

		this.setState({
			map: {
				...this.state.map,
				minLng: minLng,
				minLat: minLat,
				maxLng: maxLng,
				maxLat: maxLat,
				bounds
			}
		});

		return minLng + ';' + minLat + ';' + maxLng + ';' + maxLat;
	}

	onEdited(e){
		console.log(e)		
	}

	onDeleted(e){
		this.setState({
			map: {
				...this.state.map,
				minLng: '',
				minLat: '',
				maxLng: '',
				maxLat: ''
			}
		});
	}

	render() {
		const { name, readOnly, required } = this.props;
		const { lat, lon, minLng, minLat, maxLng, maxLat, bounds, position, zoom, crs, marker } = this.state.map;
		let markerPosition = (marker[0] !== '' && marker[1] !== '') ? [marker[1], marker[0]] : undefined;
		const showCoordinates = (typeof this.props.showCoordinates !== 'undefined') ? this.props.showCoordinates : true;
		const self = this;
		const Markers = (name) => {
			const map = useMapEvents({
				click(e) {
					self.handleMapClick(e, name)
				},
			});
			return (
				markerPosition ?
					<Marker position={markerPosition} style={{ width: 10 + 'px', height: 10 + 'px' }} />
					: null
			)
		}
		
		return (
			<FormGroup>
				<Row>
					{showCoordinates &&
						<Col xs="12" md="6" lg="4">
							<FormGroup>
								<InputGroup>
									<InputGroupAddon addonType="prepend">
										<InputGroupText><T>projection system</T></InputGroupText>
									</InputGroupAddon>
									<Input id={`${name}_proj`} type="select" name={name} onChange={this.handleCRSChange} value={crs} readOnly={this.props.options.bbox === 'true' ? true : false}>
										<option value="EPSG:3857">Google Map</option>
										<option value="EPSG:4326">WGS84</option>
										<option value="EPSG:2100">ΕΓΣΑ87</option>
									</Input>
								</InputGroup>
							</FormGroup>
							{
								this.props.options.bbox === 'true'
								?
								<FormGroup tag="fieldset">
									<FormGroup>
										<InputGroup>
											<InputGroupAddon addonType="prepend">
												<InputGroupText>minLng</InputGroupText>
											</InputGroupAddon>
											<Input
												id={`${name}_minLng`}
												type="number"
												min={crs === 'EPSG:4326' ? 18.270 : (crs === 'EPSG:2100' ? -34387.67 : 2033807.096)}
												max={crs === 'EPSG:4326' ? 29.970 : (crs === 'EPSG:2100' ? 1056496.843 : 3336245.136)}
												step={crs === 'EPSG:4326' ? 0.00000001 : 0.001}
												required={required}
												name={name}
												value={minLng}
												onChange={(event) => this.handleCoordinatesChange(event, 'minLng')}
												readOnly={readOnly}
											/>
										</InputGroup>
									</FormGroup>
									<FormGroup>
										<InputGroup>
											<InputGroupAddon addonType="prepend">
												<InputGroupText>minLat</InputGroupText>
											</InputGroupAddon>
											<Input
												id={`${name}_minLat`}
												type="number"
												min={crs === 'EPSG:4326' ? 33.230 : (crs === 'EPSG:2100' ? 3691163.514 : 3925872.561)}
												max={crs === 'EPSG:4326' ? 41.770 : (crs === 'EPSG:2100' ? 4641211.322 : 5126588.574)}
												step={crs === 'EPSG:4326' ? 0.00000001 : 0.001}
												required={required}
												name={name}
												value={minLat}
												onChange={(event) => this.handleCoordinatesChange(event, 'minLat')}
												readOnly={readOnly}
											/>
										</InputGroup>
									</FormGroup>
									<FormGroup>
										<InputGroup>
											<InputGroupAddon addonType="prepend">
												<InputGroupText>maxLng</InputGroupText>
											</InputGroupAddon>
											<Input
												id={`${name}_maxLng`}
												type="number"
												min={crs === 'EPSG:4326' ? 18.270 : (crs === 'EPSG:2100' ? -34387.67 : 2033807.096)}
												max={crs === 'EPSG:4326' ? 29.970 : (crs === 'EPSG:2100' ? 1056496.843 : 3336245.136)}
												step={crs === 'EPSG:4326' ? 0.00000001 : 0.001}
												required={required}
												name={name}
												value={maxLng}
												onChange={(event) => this.handleCoordinatesChange(event, 'maxLng')}
												readOnly={readOnly}
											/>
										</InputGroup>
									</FormGroup>
									<FormGroup>
										<InputGroup>
											<InputGroupAddon addonType="prepend">
												<InputGroupText>maxLat</InputGroupText>
											</InputGroupAddon>
											<Input
												id={`${name}_maxLat`}
												type="number"
												min={crs === 'EPSG:4326' ? 33.230 : (crs === 'EPSG:2100' ? 3691163.514 : 3925872.561)}
												max={crs === 'EPSG:4326' ? 41.770 : (crs === 'EPSG:2100' ? 4641211.322 : 5126588.574)}
												step={crs === 'EPSG:4326' ? 0.00000001 : 0.001}
												required={required}
												name={name}
												value={maxLat}
												onChange={(event) => this.handleCoordinatesChange(event, 'maxLat')}
												readOnly={readOnly}
											/>
										</InputGroup>
									</FormGroup>
								</FormGroup>
								:
								<FormGroup tag="fieldset">
									<FormGroup>
										<InputGroup>
											<InputGroupAddon addonType="prepend">
												<InputGroupText><T>lat</T>/y</InputGroupText>
											</InputGroupAddon>
											<Input
												id={`${name}_lat`}
												type="number"
												min={crs === 'EPSG:4326' ? 33.230 : (crs === 'EPSG:2100' ? 3691163.514 : 3925872.561)}
												max={crs === 'EPSG:4326' ? 41.770 : (crs === 'EPSG:2100' ? 4641211.322 : 5126588.574)}
												step={crs === 'EPSG:4326' ? 0.00000001 : 0.001}
												required={required}
												name={name}
												value={lat}
												onChange={(event) => this.handleCoordinatesChange(event, 'lat')}
												readOnly={readOnly}
											/>
										</InputGroup>
									</FormGroup>
									<FormGroup>
										<InputGroup>
											<InputGroupAddon addonType="prepend">
												<InputGroupText><T>lon</T>/x</InputGroupText>
											</InputGroupAddon>
											<Input
												id={`${name}_lon`}
												type="number"
												min={crs === 'EPSG:4326' ? 18.270 : (crs === 'EPSG:2100' ? -34387.67 : 2033807.096)}
												max={crs === 'EPSG:4326' ? 29.970 : (crs === 'EPSG:2100' ? 1056496.843 : 3336245.136)}
												step={crs === 'EPSG:4326' ? 0.00000001 : 0.001}
												required={required}
												name={name}
												value={lon}
												onChange={(event) => this.handleCoordinatesChange(event, 'lon')}
												readOnly={readOnly}
											/>
										</InputGroup>
									</FormGroup>
								</FormGroup>
							}
						</Col>
					}
					<Col sm="12" md={showCoordinates ? 6 : 12} lg={showCoordinates ? 8 : 12}>
						<VisibilitySensor>
							<LeafletMap
								ref={this.mapRef}
								style={{ width: 100 + '%', height: 300 + 'px' }}
								center={!this.state.clicked ? (markerPosition || position) : undefined}
								zoom={!this.state.clicked ? zoom : undefined}
								attributionControl={false}
							>
								<TileLayer
									url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
									attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
								/>
								{
									this.props.options.bbox === 'true' ?
										<FeatureGroup>
											<EditControl
												position="topright"
												draw={{
													polyline: false,
													polygon: false,
													circle: false,
													marker: false,
													circlemarker: false,
													rectangle: true,
												}}
												edit={{
													remove: false,
													edit: false
												}}
												onDrawStart={this.onDrawStart}
												// onEdited={this.onEdited}
												onCreated={this.onCreated}
												// onDeleted={this.onDeleted}
											/>
										</FeatureGroup>
										:
										<Markers event={name} />
								}
								<Rectangle bounds={bounds} weight={1} />
							</LeafletMap>
						</VisibilitySensor>
					</Col>
				</Row>
			</FormGroup>
		);
	}

}

Map.propTypes = {
	value: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	options: PropTypes.object,
	required: PropTypes.bool.isRequired,
	readOnly: PropTypes.bool.isRequired,
	geoCoding: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.array,
	]),
	showCoordinates: PropTypes.bool,
}

export default Map;
