import React, { useState } from "react";
import { Row, Col, Button } from 'reactstrap';

import { AsyncPaginate } from "input-fields";
import T from 'modules/i18n';

const CustomApplicationNumber = ({ value, setValue }) => {
    return (
        <React.Fragment>
            <Row>
                <Col><T>not found</T></Col>
                {
                    // value.length > 1 &&
                    // <Col>
                    //     <Button onClick={() => setValue({ value, label: value })}><T>continue without search</T></Button>
                    // </Col>
                }
            </Row>
        </React.Fragment>
    );
}

const ApplicationElement = ({ name, value, className, disabled, readOnly, required, loadOptions, isMulti, onChange }) => {
    const [applicationValue, setApplicationValue] = useState((!value || value === '') ? '' : (isMulti ? value.map(e => e) : { value, label: value }));
    const [enteredValue, setEnteredValue] = useState('');

    const applyChange = (value) => {
        // let strValue;
        // if (value === null) {
        //     strValue = '';
        // } else {
        //     strValue = isMulti ? value.map(e => e.value).join(';') : value.value;
        // }
        // onChange({ target: { type: 'select', value: strValue, name } });        
        onChange({ target: { type: 'select', value, name: name } });
    }

    const handleValueSelect = (value) => {
        const fullValue = isMulti ? [...applicationValue, value] : value;
        setApplicationValue(fullValue);
        setEnteredValue('');
        applyChange(fullValue);
    };

    const handleInputChange = (inputValue, { action, prevInpuValue }) => {
        switch (action) {
            case 'input-change':
                setEnteredValue(inputValue);
                break;

            case 'input-blur':
            case 'menu-close':
                setEnteredValue('');
                break;

            default:
                break;
        }
    };

    const handleChange = (value) => {
        setApplicationValue(value);
        applyChange(value);
    };

    return (
        <AsyncPaginate
            className={className}
            name={name}
            value={applicationValue}
            loadOptions={loadOptions}
            onChange={(value) => handleChange(value)}
            isClearable={!isMulti}
            additional={{ page: 1 }}
            inputProps={{ required }}
            disabled={disabled}
            readOnly={readOnly}
            required={required}
            isMulti={isMulti}
            backspaceRemovesValue={true}
            isOptionSelected={true}
            onInputChange={handleInputChange}
            noOptionsMessage={() => (
                <CustomApplicationNumber value={enteredValue} setValue={(value) => handleValueSelect(value)} />
            )}
        />
    );
}

export default ApplicationElement;
