import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import Message from 'core/views/modals/message';
import { toggleModal } from 'core/ducks/ui/modal';

import * as roles from 'core/model/roles';
import {
	Table, Title, Button, Tbody, Thead, Toolbox, Tr, Search, Pagination
} from 'table';
import { StaticRoutes } from '../../model/routes';
import { requestData, getData, getInfo } from 'core/ducks/list';
import { Loading } from 'core/components';
import { ErrorPage } from 'core/views/pages';
import { ExportToCSV } from 'core/model/lib';
import T from 'modules/i18n';

class Organization extends Component {

	constructor(props) {
		super(props);
    this.actions = bindActionCreators({toggleModal}, props.dispatch);
		this.initialState = {
			query: '',
			status: '',
			sort: 'created',
			sort_method: 'desc',
			httpStatus: 200
		};

		this.state = { ...this.initialState, page: 1, refreshing: false };

		this.fields = ['vat_number', 'country', 'title', 'address', 'phonenumber', 'email', 'type'];

		this.layout = {
			vat_number: { sortable: true },
			country: { sortable: true },
			title: { sortable: true },
			address: { sortable: true },
			phonenumber: { sortable: true },
			email: { sortable: true },
			type: { sortable: true }
		};

		this.fetchData = this.fetchData.bind(this);
		this.createUrl = this.createUrl.bind(this);
		this.handlePageChange = this.handlePageChange.bind(this);
		this.handleFilterChange = this.handleFilterChange.bind(this);
		this.handleToolboxReset = this.handleToolboxReset.bind(this);
		this.handleCSVExport = this.handleCSVExport.bind(this);
		this.handleSortChange = this.handleSortChange.bind(this);
	}

	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.query !== this.state.query) {
			let state = { page: 1 };
			if (prevState.query !== this.state.query)
				state = Object.assign(state, { query: this.state.query });
			this.setState(state, this.fetchData);
		}
	}

	fetchData() {
		const url = this.createUrl();
		this.props.dispatch(
			requestData('organization', url)
		).catch(httpStatus => {
			this.setState({ httpStatus });
		});
	}

	createUrl(scope = '') {
		let { query, sort, sort_method } = this.state;
		let url = `app/organization/page/${this.state.page}/sort/${sort}/sort_method/${sort_method}`;
		query = (query !== '') ? '/query/' + query : '';
		let fq = [];
		if (fq.length > 0) {
			fq = fq.join(';');
			fq = `/fq/${fq}`;
		} else {
			fq = '';
		}
		url += query + fq;
		if (scope === 'csv')
			url += '/limit/100000';

		return url;
	}

	handlePageChange(page) {
		this.setState({ page }, this.fetchData);
	}

	handleFilterChange(event) {
		this.setState({
			[event.target.name]: event.target.value,
			page: 1
		});
	}

	handleToolboxReset() {
		this.setState({
			...this.initialState,
			page: 1
		}, this.fetchData);
		let url = StaticRoutes.Organization;
		if (this.props.profile.role !== roles.AUTHORIZED) {
			url = StaticRoutes.AdminOrganization;
		}
		this.props.history.push(url);
	}

	handleCSVExport() {
		const { messages } = this.props.i18n || { messages: {} };
		const url = this.createUrl('csv');
		this.props.dispatch(requestData('csv', url)).then(() => {
			let data = Object.keys(this.props.list.csv.data.values).map((index) => {
				let row = this.props.list.csv.data.values[index];
				return this.fields.map((col) => {
					return (this.layout[col] && this.layout[col].type === 'translatable' && messages[row[col]]) ? messages[row[col]] : row[col];
				});
			});
			let fields = this.fields.map((field) => {
				return messages[field] ? messages[field] : field;
			});
			let csv = new ExportToCSV('organizations.csv', fields, data);
			csv.createLink();
		});
	}

	handleSortChange(sort) {
		if (sort === this.state.sort) {
			this.setState({ sort_method: this.state.sort_method === 'asc' ? 'desc' : 'asc' }, this.fetchData);
		} else {
			this.setState({ sort }, this.fetchData);
		}
	}

  downloadRepresentationCertificate = (data) => {
		const url = `/data/app/${data}`;
    if(data)
		  window.open(url);
	}

  downloadSharing  = (data) => {
    const sharing = JSON.parse(data);
		let i = true;
    sharing && Object.keys(sharing).forEach(index => {
      if (sharing[index] !== 'true' && sharing[index] !== 'false' && sharing[index] !== '' && sharing[index] !== null) {
				i = false;
        const url = `/data/app/${sharing[index]}`;
        window.open(url);
      }
    })
		if(i){
			this.actions.toggleModal(true,
				<Message
					toggle={this.actions.toggleModal}
					title="foreign_beneficiary_properties"
					message="no data found"
				/>
			)
		}
	}


	render() {

		const { data, info, list } = this.props;
		if (this.state.httpStatus !== 200)
			return (<ErrorPage status={this.state.httpStatus} />);
		if (list.organization.status !== 200 && list.organization.status !== '')
			return (<ErrorPage status={list.organization.status} />);

		const { messages } = this.props.i18n || { messages: {} };

		return (
			<Row>
				<Col>
					<Table>
						<Title>
							<T>organization</T>
							<Button type="toolbox" title="filters" className="float-right" />
						</Title>
						<Toolbox onReset={this.handleToolboxReset}>
							<Row>
								<Col xs="12" lg="12" className="form-group text-right">
									<Search placeholder={`${messages.search || 'search'}...`} onChange={this.handleFilterChange} name="query" />
									<Button type="csv" title={`${messages.export || 'export'} csv`} onClick={this.handleCSVExport}>
										<T>export</T> csv
									</Button>
									<Button type="resetFilters" title={messages['reset filters'] || 'reset filters'}><T>reset</T></Button>
								</Col>
							</Row>
						</Toolbox>
						<Thead>
							<Tr
								className="text-capitalize"
								data={[...this.fields]}
								layout={this.layout}
								sortBy={this.state.sort}
								sortMethod={this.state.sort_method}
								onClick={this.handleSortChange}
							/>
						</Thead>
						{
							this.props.pending || !data ? <Loading /> :
								<Tbody refreshing={this.state.refreshing}>
									<Tr
										data={data ? data : {}}
										layout={this.layout}
										order={this.fields}
										badge_colors={this.badge_colors}
									>
									{
                  // <Button type="file-archive-o" title={messages['foreign_beneficiary_properties'] || 'beneficiary properties'}
                  //   onClick={(data) => {
                  //     const sharing = JSON.parse(data.properties);
                  //     if(!sharing || Object.keys(sharing).length === 0){
                  //       this.actions.toggleModal(true,
                  //         <Message
                  //           toggle={this.actions.toggleModal}
                  //           title="foreign_beneficiary_properties"
                  //           message="no data found"
                  //         />
                  //       )
                  //     }else{
                  //       this.downloadSharing(data.properties)
                  //     }
                  //   }} />
                  // <Button type="file-archive-o" title={messages['representation certificate'] || 'representation certificate'}
                  //   onClick={(data) => {
                  //     !data.representation_certificate && this.actions.toggleModal(true,
                  //       <Message
                  //         toggle={this.actions.toggleModal}
                  //         title="representation certificate"
                  //         message="no data found"
                  //       />
                  //     )
                  //     this.downloadRepresentationCertificate(data.representation_certificate)
                  //   }} />
									}
									</Tr>
								</Tbody>
						}
						<Pagination
							className="mx-auto"
							page={info.page}
							total={info.total_pages}
							onClick={(page) => {
								if (page !== info.page)
									this.handlePageChange(page);
							}}
						/>
					</Table>
				</Col>
			</Row>
		);
	}
}

const mapStateToProps = (state) => ({
	profile: state.profile.user,
	list: state.list,
	pending: state.list.organization.pending,
	data: getData(state, 'organization'),
	info: getInfo(state, 'organization'),
	viewData: state.update.response,
	http_status: state.update.status,
	i18n: state.i18n,
	user: state.profile.user
});

Organization.propTypes = {
	withoutToolBox: PropTypes.bool // Does not display the toolbox
};

Organization = connect(mapStateToProps)(Organization);

export default Organization;

