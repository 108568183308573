import { StaticRoutes } from './model/routes';
import * as roles from 'core/model/roles';
import applicationRoles from 'app/model/appRoles';

const menu = [
	{
		name: 'home',
		url: StaticRoutes.AppDashboard,
		icon: 'fa fa-home',
		role: roles.AUTHORIZED,
		domain: 'app',
	},
	{
		name: 'organizations',
		url: StaticRoutes.AdminOrganizations,
		icon: 'fa fa-users',
		role: [roles.ADMIN, roles.EDITOR, roles.REVIEWER],
		domain: 'admin',
	},
	{
		name: 'applications',
		url: StaticRoutes.Applications,
		icon: 'fa fa-list-alt',
		role: roles.AUTHORIZED,
		domain: 'app',
		appRole: [applicationRoles.REPRESENTATIVE, applicationRoles.ADMINISTRATOR]
	},
	{
		name: 'applications',
		url: StaticRoutes.AdminApplications,
		icon: 'fa fa-list-alt',
		role: [roles.ADMIN, roles.EDITOR, roles.REVIEWER],
		domain: 'admin',
	},
	// {
	// 	name: 'certificates',
	// 	url: StaticRoutes.Certificates,
	// 	icon: 'fa fa-certificate',
	// 	role: roles.AUTHORIZED,
	// 	domain: 'app',
	// 	appRole: [applicationRoles.REPRESENTATIVE, applicationRoles.ADMINISTRATOR]
	// },
	// {
	// 	name: 'certificates',
	// 	url: StaticRoutes.AdminCertificates,
	// 	icon: 'fa fa-certificate',
	// 	role: [roles.ADMIN, roles.EDITOR, roles.REVIEWER],
	// 	domain: 'admin',
	// },
	// {
	// 	name: 'receipts',
	// 	url: StaticRoutes.Receipts,
	// 	icon: 'fa fa-money',
	// 	role: roles.AUTHORIZED,
	// 	domain: 'app',
	// 	appRole: [applicationRoles.REPRESENTATIVE, applicationRoles.ADMINISTRATOR]
	// },
	// {
	// 	name: 'receipts',
	// 	url: StaticRoutes.AdminReceipts,
	// 	icon: 'fa fa-money',
	// 	role: [roles.ADMIN, roles.EDITOR, roles.REVIEWER],
	// 	domain: 'admin',
	// },
	{
		name: 'administration',
		url: StaticRoutes.Administration,
		icon: 'fa fa-cogs',
		role: roles.AUTHORIZED,
		domain: 'app',
		appRole: [applicationRoles.ORGANIZATION, applicationRoles.ADMINISTRATOR],
	},
];

export default menu;