import React from 'react';
import {
	Card, CardBody, CardText, Form, FormGroup, FormText, Button, Row, Col, Label, Input, Badge
} from 'reactstrap';

import T from 'modules/i18n';

const FinalSubmit = ({ content, node, onSubmit, values, disabled }) => {

	const handleSubmit = (e) => {
		e.preventDefault();
		const formdata = new FormData();
		formdata.append(node.mname, true);
		onSubmit(formdata);
	}

	return (
		<Card>
			<CardBody>
				<Form onSubmit={handleSubmit}>
					{ !disabled && <FormText><T>navigation_tip</T></FormText> }
					<Row className="m-2 p-2">
						<Col className="mr-3">
							{ Object.entries(values).map(([field, {label, value, type}]) => (
								value !== '' && <FormGroup key={`summary_${field}`} row className="m-2 p-2">
									<Col lg="4" className="mr-2 text-lg-right">
										<Label className="application-label">{label.replace(/\[\[.*?\]\]/g, '')}</Label>
									</Col>
									<Col lg="7" className="pr-lg-5">
										{ type === 'attachment'
											? <Badge color="info" className="p-1" pill href={`${value}`} target="_blank"><T>download</T></Badge>
											: type === 'category'
											? <React.Fragment>
													<Input plaintext className="text-muted font-weight-bold" value={`${JSON.parse(value).label}`}/>
													{ JSON.parse(value).subLabel && <Input plaintext className="text-muted font-weight-bold" value={`${JSON.parse(value).subLabel}`}/>}
												</React.Fragment>
											: type === 'application'
											? JSON.parse(value).map(e => <Input key={`summary_${field}_${label.replace(/\[\[.*?\]\]/g, '')}_${Math.random()}`} plaintext className="text-muted font-weight-bold" value={e.label}/>)
											: <Input plaintext className="text-muted font-weight-bold" value={value}/>
										}
									</Col>
								</FormGroup>
							))}
						</Col>
					</Row>
					{ !disabled && (
						<div className="border rounded border-info px-4 py-3">
							<Row>
								<Col>
									<CardText dangerouslySetInnerHTML={{__html: content}}/>
								</Col>
							</Row>
							<Row>
								<Col className="d-flex justify-content-end">
									<Button color="warning" disabled={disabled}><T>submit</T></Button>
								</Col>
							</Row>
						</div>
					)}
				</Form>
			</CardBody>
		</Card>
	);
}

export default FinalSubmit;
