import React, { Component, useState } from 'react';
import { Map, Radio, CheckBox, GeneralInput, File, VatNumber, AsyncPaginate } from 'input-fields';
import { Row, Col, Label, FormText, Input, FormGroup, Modal, ModalBody, FormFeedback, Badge } from 'reactstrap';
import ApplicationElement from './applicationsElement';
import CategoryElement from './categoryElement';
import PropTypes from 'prop-types';
import T from 'modules/i18n';

const SwitchInput = ({ field, value, onChange, onValidationChange, hidden }) => {
	const types = {string: 'text', text: 'textarea'};
	const field_options = (field.options && JSON.parse(field.options)) ? JSON.parse(field.options) : undefined;

	switch (field.type) {
		case 'radio':
			return (
				<Radio
					value={value}
					required={field.is_required}
					name={field.name}
					onChange={onChange}
					text={field_options}
					readOnly={field.final || field.readOnly}
					disabled={hidden}
				/>
			);

		case 'checkbox':
			return (
				<CheckBox
					value={value}
					required={field.is_required}
					name={field.name}
					onChange={onChange}
					options={field_options}
					readOnly={field.final || field.readOnly}
					disabled={hidden}
				/>
			);

		case 'map':
			return (
				<Map
					value={value}
					name={field.name}
					options={field_options}
					required={field.is_required}
					readOnly={field.final || field.readOnly}
					onChange={onChange}
					disabled={hidden}
				/>
			);

		case 'plaintext':
			return null;

		case 'attachment':
			return (
				<File
					id={`input_${field.name}`}
					className="w-lg-75"
					name={field.name}
					files={value}
					required={field.is_required}
					onChange={onChange}
					readOnly={field.final || field.readOnly}
					accept={(field_options && field_options.acceptedFileTypes) ? field_options.acceptedFileTypes : undefined}
					disabled={hidden}
					label={"Επιλέξτε αρχείο"}
					buttonText={"Αναζήτηση"}
					maxFileSize={(field_options && field_options.maxFileSize) ? field_options.maxFileSize : 0}
					onValidationChange={(status) => onValidationChange({[field.name]: status})}
				/>
			);

		case 'conditional':
		case 'accept':
			return (
				<Input
					type="checkbox"
					className="mr-2"
					name={field.name}
					onChange={onChange}
					required={field.is_required}
					readOnly={field.final || field.readOnly}
					disabled={hidden}
					value={(typeof value === 'string' || value instanceof String) ? value === 'true' : value}
					checked={(typeof value === 'string' || value instanceof String) ? value === 'true' : value}
				/>
			)

		case 'vat_number':
			return (
				<VatNumber
					className="w-lg-75"
					type="text"
					id={`input_${field.name}`}
					required={field.is_required}
					name={field.name}
					value={value}
					onChange={onChange}
					readOnly={field.final || field.readOnly}
					disabled={hidden}
					onValidationChange={(status) => onValidationChange({[field.name]: status})}
				/>
			);

		case 'country':
			return <AsyncPaginate
				className="w-lg-75"
				name={field.name}
				key={`country_field_${field.name}`}
				value={(_.isObject(value) || value === '') ? value : JSON.parse(value)}
				loadOptions={field.loadCountries}
				onChange={(value) => {
					onChange({target: {type: 'select', value, name: field.name}});
				}}
				isClearable={true}
				additional={{page: 1}}
				inputProps={{required: field.is_required}}
				disabled={hidden}
				readOnly={field.final || field.readOnly}
				required={field.is_required}
			/>

		case 'category':
			return <CategoryElement
				className="w-lg-75"
				name={field.name}
				key={`category_field_${field.name}`}
				value={(_.isObject(value) || value === '') ? value : JSON.parse(value)}
				loadOptions={(search, loadedOptions, additional) => field.loadCategories(search, field_options.categoryID, additional)}
				loadSubOptions={(search, loadedOptions, additional) => field.loadSubCategories(search, _.isObject(value) ? value.value : JSON.parse(value).value, additional)}
				onChange={onChange}
				disabled={hidden}
				readOnly={field.final || field.readOnly}
				required={field.is_required}
				isMulti={(field_options && field_options.isMulti) ? field_options.isMulti : false}
			/>

		case 'application':
			return <ApplicationElement
				className="w-lg-75"
				name={field.name}
				key={`application_field_${field.name}`}
				value={(_.isObject(value) || value === '') ? value : JSON.parse(value)}
				loadOptions={(search, loadedOptions, additional) => field.loadApplications(search, field_options.workflow, additional)}
				onChange={onChange}
				disabled={hidden}
				readOnly={field.final || field.readOnly}
				required={field.is_required}
				isMulti={(field_options && field_options.isMulti) ? field_options.isMulti : false}
			/>

		default:
			const type = Object.keys(types).includes(field.type) ? types[field.type] : field.type;
			return (
				<GeneralInput
					className="w-lg-75"
					id={`input_${field.name}`}
					type={type}
					required={field.is_required}
					maxLength={field.max_size>0 ? field.max_size : undefined}
					name={field.name}
					value={value}
					onChange={onChange}
					readOnly={field.final || field.readOnly}
					options={field_options ? field_options : undefined}
					disabled={hidden}
					pattern={field.validation}
				/>
			);
		}
}

const ModalLabel = ({field}) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const sup = (field.type !== 'conditional' && field.type !== 'accept' && field.is_required) ? (<sup>*</sup>) : '';
	if (!field.is_modal_enabled)
		return (<span>{field.label}{sup}</span>);
	const regex = /\[\[([^\]]*)\]\]/gm;
	const matches = field.label.split(regex);
	if (matches.length === 1)
		return (<span>{field.label}{sup}</span>);
	return (
		<React.Fragment>
			<span>{matches[0]}{matches[2]}{sup}</span>
			<div onClick={(e) => {e.preventDefault(); setIsModalOpen(true);}} className="text-info" role="link">{matches[1]}</div>
			<Modal size="lg" isOpen={isModalOpen} toggle={() => setIsModalOpen(false)}>
				<ModalBody className="p-3" dangerouslySetInnerHTML={{__html: field.modal}}/>
			</Modal>
		</React.Fragment>
	);
}

class FormElement extends Component {

	render() {
		const { field, urlPrefix } = this.props;
		switch (field.type) {
			case 'radio':
			case 'checkbox':
			case 'map':
				return (
					<Row className={`m-2 p-2 ${this.props.className ? this.props.className : ''}`}>
						<Col className="mr-3">
							<FormGroup>
								<Label className="application-label">
									<ModalLabel field={field}/>
								</Label>
								<FormText>{field.description}</FormText>
								<SwitchInput {...this.props}/>
							</FormGroup>
						</Col>
					</Row>
				);

			case 'plaintext':
				return (
					<Row className={`m-2 p-2 ${this.props.className}`}>
						<Col className="mr-3">
							<FormGroup>
								<Label className="application-label">
									<ModalLabel field={field}/>
								</Label>
								<FormText>{field.description}</FormText>
							</FormGroup>
						</Col>
					</Row>
				);

			case 'conditional':
			case 'accept':
				return (
					<Row className={`m-2 p-2 ${this.props.className}`}>
						<Col className="mr-3">
							<FormGroup check>
								<Label className="application-label" check>
									<SwitchInput {...this.props}/>
									<ModalLabel field={field}/>
								</Label>
								<FormText>{field.description}</FormText>
							</FormGroup>
						</Col>
					</Row>
				);

			case 'vat_number':
				return (
					<FormGroup row className={`m-2 p-2 ${this.props.className}`}>
						<Col lg="4" className="mr-2 text-lg-right">
							<Label htmlFor={`input_${field.name}`} className="application-label">
								<ModalLabel field={field}/>
							</Label>
						</Col>
						<Col lg="7" className="pr-lg-5">
							<FormGroup>
								<SwitchInput {...this.props}/>
							</FormGroup>
						</Col>
					</FormGroup>
				);

			case 'attachment':
				return (
					<FormGroup row className={`m-2 p-2 ${this.props.className}`}>
						<Col lg="4" className="mr-2 text-lg-right">
							<Label htmlFor={`input_${field.name}`} className="application-label">
								<ModalLabel field={field}/>
							</Label>
						</Col>
						<Col lg="7" className="pr-lg-5">
							<FormGroup>
								<SwitchInput {...this.props}/>
								<FormText>{field.description}</FormText>
								<div className="w-lg-75 d-flex justify-content-start p-2">
									{ (this.props.value && (typeof this.props.value === 'string' || this.props.value instanceof String) && this.props.value !== '') &&
										<Badge className="p-1" pill href={`${this.props.value}`} target="_blank"><T>download</T></Badge>
									}
								</div>
							</FormGroup>
						</Col>
					</FormGroup>
				);

			default:
				return (
					<FormGroup row className={`m-2 p-2 ${this.props.className}`}>
						<Col lg="4" className="mr-2 text-lg-right">
							<Label htmlFor={`input_${field.name}`} className="application-label">
								<ModalLabel field={field}/>
							</Label>
						</Col>
						<Col lg="7" className="pr-lg-5">
							<FormGroup>
								<SwitchInput {...this.props}/>
								<FormText>{field.description}</FormText>
								{ field.validation_msg &&
									<FormFeedback>{field.validation_msg}</FormFeedback>
								}
								{ (this.props.prevValue && this.props.prevValue != this.props.value) &&
									<FormText color="muted" className="pl-1">{this.props.prevValue}{' '}<sup>*</sup></FormText> 
								}
							</FormGroup>
						</Col>
					</FormGroup>
				);
		}
	}
}

FormElement.propTypes = {
	field: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	value: PropTypes.any,
	hidden: PropTypes.bool,
	onValidationChange: PropTypes.func,
	urlPrefix: PropTypes.string.isRequired,
	prevValue: PropTypes.any,
};

FormElement.defaultProps = {
	hidden: false,
	prevValue: null,
}

export default FormElement;
