import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { buildPath } from 'core/model/lib/urlTools';
import { StaticRoutes, DynamicRoutes } from '../../model/routes';
import Action from '../../components/action';
import { openInvitationsModal } from '../../ducks/appState';
import T from 'modules/i18n';

class Actions extends Component {
	constructor(props) {
		super(props);
		this.state = {
			actions: [],
		};
	}

	componentDidMount() {
		this.constructActions();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.profile !== this.props.profile) {
			this.constructActions();
		}
	}

	constructActions = () => {
		const actions = this.availableActions().map(action => this.getActionProps(action));
		this.setState({actions});
	}

	getActionProps = (action) => {
		const defaultProps = { action, placeholders: '' };
		switch (action) {
			case 'initiate':
				return {
					...defaultProps,
					onClick: () => this.props.history.push(StaticRoutes.InitiateOrg),
					text: this.props.profile.type === 'legal' ? 'declate details' : 'initiate organization',
				};

			case 'editDetails':
				return {
					...defaultProps,
					onClick: () => this.props.history.push(StaticRoutes.Administration),
					text: 'edit details',
				};

			case 'foreign':
				return {
					...defaultProps,
					onClick: () => this.props.history.push(StaticRoutes.Foreign),
					text: 'foreign organization',
				};

			case 'invitations':
				return {
					...defaultProps,
					onClick: () => this.props.dispatch(openInvitationsModal(false)),
					text: 'invitations',
				};

			case 'proxy':
				return {
					...defaultProps,
					onClick: () => this.props.history.push({pathname: StaticRoutes.Administration, state: ['proxy']}),
					text: 'edit add proxies',
				};

			case 'admin':
				return {
					...defaultProps,
					onClick: () => this.props.history.push({pathname: StaticRoutes.Administration, state: ['member_addition']}),
					text: 'edit administrator',
				};

			case 'pending':
				return {
					...defaultProps,
					onClick: () => this.props.history.push(StaticRoutes.Applications + '/status/progressing'),
					text: 'pending applications'
				}

			default:
				return {
					...action,
					onClick: () => {this.props.history.push(buildPath(DynamicRoutes.Apply, {round: action.name}))},
					text: null,
					placeholders: action.label
				};
		}
	}

	availableActions = () => {
		const { profile } = this.props;
		const actions = [];
		if (profile.type === 'legal') {
			if (profile.roles.length === 0) {
				actions.push('initiate');
			} else {
				actions.push('editDetails');
				actions.push('admin');
			}
			return actions;
		}
		if (profile.roles.includes('proxy')) {
			if (profile.pendingApplications > 0)
				actions.push('pending');
			if (Object.keys(profile.openApplications).length > 0) {
				Object.entries(profile.openApplications)
					.filter(([name, {open}]) => !open)
					.forEach(([name, {label}]) =>
						actions.push({action: 'apply', name, label})
					);
				Object.entries(profile.openApplications)
					.filter(([name, {open}]) => open)
					.forEach(([name, {label}]) =>
						actions.push({action: 'objection', name, label})
					)
			}
		}
		if (profile.invitations.length > 0)
			actions.push('invitations');
		if (profile.roles.length === 0)
			actions.push('initiate');
		// if (!profile.active || profile.active === profile.userid) {
		// 	actions.push('foreign');
		// }
		if (profile.roles.includes('administrator') || profile.roles.includes('owner')) {
			actions.push('proxy');
			actions.push('editDetails');
		}
		if (profile.roles.includes('owner'))
			actions.push('admin');
		return actions;
	}

	render() {
		return this.state.actions.map(({action, text, placeholders, ...otherProps}, index) => (
			<Action key={`action_${action}_${index}`} action={action} {...otherProps}>
				<T lettercase="capitalize">{text}</T><br/>{placeholders}
			</Action>
		));
	}
}

const mapStateToProps = (state) => ({
	profile: state.appProfile,
});

Actions = connect(mapStateToProps)(Actions);

export default withRouter(Actions);
