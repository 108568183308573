import * as roles from 'core/model/roles';
import appRoles from 'app/model/appRoles';
import { StaticRoutes as CoreRoutes } from 'core/model/routes';

const App = '/app';
const AppDashboard = '/app/dashboard';
const Applications = App + '/applications';
// const Certificates = App + '/certificates';
// const Receipts = App + '/payments';
const Administration = App + '/administration';
const InitiateOrg = App + '/initiate';
const Foreign = App + '/foreign';
const Objection = App + '/objection';

const { Admin, AdminDashboard } = CoreRoutes;
const AdminOrganizations = Admin + '/organizations';
const AdminApplications = Admin + '/applications';
// const AdminCertificates = Admin + '/certificates';
// const AdminReceipts = Admin + '/payments';

export const StaticRoutes = {
	App,
	AppDashboard,
	AdminDashboard,
	AdminOrganizations,
	Applications,
	AdminApplications,
	// Certificates,
	// AdminCertificates,
	// Receipts,
	// AdminReceipts,
	Administration,
	InitiateOrg,
	Foreign,
	Objection,
};

const Apply = App + '/apply/:round/:application?/:step?';
const AdminApply = Admin + '/apply/:round/:application?/:step?';
const ApplicationsStatus =  Applications + '/(status)?/:status?/(round)?/:round?';
const AdminApplicationsStatus  =  AdminApplications + '/(status)?/:status?/(workflow)?/:workflow?/(round)?/:round?/(step)?/:step?';
// const CertificatesStatus =  Certificates + '/(status)?/:status?';
// const AdminCertificatesStatus  =  AdminCertificates + '/(status)?/:status?';
// const ReceiptsStatus =  Receipts + '/(payoff)?/:payoff?';
// const AdminReceiptsStatus  =  AdminReceipts + '/(payoff)?/:payoff?/(organization_type)?/:organization_type?';

export const DynamicRoutes = {
	Apply,
	AdminApply,
	ApplicationsStatus,
	AdminApplicationsStatus,
	// CertificatesStatus,
	// AdminCertificatesStatus,
	// ReceiptsStatus,
	// AdminReceiptsStatus
};

export const routes = [
	{
		url: App,
		name: 'app',
		role: [roles.GUEST],
		to: '/api/oauth/oauth2',
		externalUrl: true,
		exact: false,
	},
	{
		url: App,
		name: 'app',
		to: AppDashboard,
		role: roles.AUTHORIZED,
		exact: true,
	},
	{
		url: AppDashboard,
		name: 'app',
		role: [roles.GUEST],
		component: 'AppDashboard',
		exact: false,
		appRole: appRoles.NONE,
	},
	{
		url: AppDashboard,
		name: 'app',
		role: roles.AUTHORIZED,
		component: 'AppDashboard',
		exact: false,
		appRole: appRoles.NONE,
	},
	{
		url: AdminDashboard,
		name: 'dashboard',
		component: 'AdminDashboard',
		role: roles.REVIEWER,
	},
	{
		url: AdminOrganizations,
		name: 'organizations',
		role: [roles.ADMIN, roles.EDITOR, roles.REVIEWER],
		component: 'Organizations',
	},
	{
		url: ApplicationsStatus,
		name: 'applications',
		role: roles.AUTHORIZED,
		component: 'Applications',
		appRole: appRoles.ADMINISTRATOR,
	},
	{
		url: AdminApplicationsStatus,
		name: 'applications',
		role: [roles.ADMIN, roles.EDITOR, roles.REVIEWER],
		component: 'Applications',
	},
	// {
	// 	url: CertificatesStatus,
	// 	name: 'licences',
	// 	role: roles.AUTHORIZED,
	// 	component: 'Certificates',
	// 	appRole: appRoles.NONE,
	// },
	// {
	// 	url: AdminCertificatesStatus,
	// 	name: 'licences',
	// 	role: [roles.ADMIN, roles.EDITOR, roles.REVIEWER],
	// 	component: 'Certificates',
	// },
	// {
	// 	url: ReceiptsStatus,
	// 	name: 'payment',
	// 	role: roles.AUTHORIZED,
	// 	component: 'Receipts',
	// 	appRole: appRoles.NONE,
	// },
	// {
	// 	url: AdminReceiptsStatus,
	// 	name: 'payment',
	// 	role: [roles.ADMIN, roles.EDITOR, roles.REVIEWER],
	// 	component: 'Receipts',
	// },
	{
		url: Administration,
		name: 'administration',
		role: [roles.AUTHORIZED],
		component: 'Administration',
		// appRole: appRoles.ADMINISTRATOR,
	},
	{
		url: Administration,
		name: 'administration',
		role: roles.REVIEWER,
		to: CoreRoutes.Admin,
	},
	{
		url: InitiateOrg,
		name: 'initiate',
		role: roles.AUTHORIZED,
		component: 'InitiateOrg',
	},
	{
		url: Foreign,
		name: 'foreign',
		role: roles.AUTHORIZED,
		component: 'Foreign',
	},
	{
		url: Apply,
		name: 'apply',
		role: roles.AUTHORIZED,
		component: 'Apply',
	},
	{
		url: AdminApply,
		name: 'apply',
		role: roles.REVIEWER,
		component: 'Apply',
		contextComponent: 'ApplySidebar',
	}
];

const combined = {StaticRoutes, DynamicRoutes, routes};

export default combined;
