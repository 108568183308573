import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button } from 'reactstrap';

import Card from '../../components/collapsingCard';
import { Form } from 'flows/views/components';
import { getData } from 'core/ducks/update';
import { uploadData } from 'core/ducks/upload';
import { StaticRoutes } from '../../model/routes';
import withApp from './app';
import T from 'modules/i18n';

class InitiateOrg extends Component {

	constructor(props) {
		super(props);
		this.endpoints = {
			beneficiary_details: 'app/organization',
			// beneficiary_properties: 'app/organizationProperties',
			legal_representative: 'app/representative',
			member_addition: '',
		}
		this.forms = Object.keys(this.endpoints);
		this.state = {
			values: {},
			valuesToHide: [],
			readOnly: {
				beneficiary_details: ['vat_number', 'email'],
				// beneficiary_properties: [],
				legal_representative: [],
				member_addition: [],
			},
			hasAccepted: this.forms.reduce((obj, form) => ({...obj, [form]: false}), {}),
			steps: ['beneficiary_details'],
			concluded: false,
		};
	}

	setData = (data) => {
		const keyMappings = {
			freetype: 'type',
			vat_number: 'vat_number',
			identifier: 'vat_number',
			title: 'title',
			name: 'title',
			address: 'address',
			email: 'email',
			phonenumber: 'phonenumber',
		}
		const beneficiary_details = Object.entries(data.values.beneficiary_details)
			.filter(([key, value]) => key in keyMappings)
			.reduce((obj, [key, value]) => ({
				...obj,
				[keyMappings[key]]: value
			}), {}
		);
		this.setState({
			...data,
			values: {
				...data.values,
				beneficiary_details,
			}
		});
	}

	componentDidMount() {
		this.props.dispatch(getData('app/initiate')).then(data => this.setData(data));
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.concluded !== this.state.concluded && this.state.concluded) {
			this.props.getDefaultProfile(true, () => {
				if (this.props.user.user_id === this.state.values.member_addition.vat_number) {
					this.props.history.replace(StaticRoutes.Administration);
				} else {
					this.props.history.replace(StaticRoutes.AppDashboard);
				}
			});
		}
	}

	handleAcceptanceChange = (scope, hasAccepted) => {
		this.setState((prevState, props) => ({
			hasAccepted: {...prevState.hasAccepted, [scope]: hasAccepted}
		}));
	}

	handleValidationChange = (scope, target) => {
		if (scope === 'member_addition' &&  Object.keys(target).includes('vat_number')) {
			const readOnly = target['vat_number'] ? [] : ['firstname', 'lastname', 'email', 'phonenumber'];
			this.setState({
				readOnly: {...this.state.readOnly, member_addition: readOnly}
			});
		}
	}

	handleSubmit = (step, data) => {
		data.append('step', step);

		this.props.dispatch(uploadData('app/initiate', data))
			.then((response) => this.setState({...response}));
	}

	render() {
		const { hasAccepted, steps } = this.state;
		if (Object.keys(this.state.values).length < 1) return null;
		return this.forms.map(form => (
				<Card
					key={`administration_form_${form}`}
					title={this.props.i18n[form]}
					className="m-2 p-2 w-75"
					disabled={!steps.includes(form)}
					initiallyOpen={steps[steps.length - 1] === form}
				>
					<Form
						form={form}
						values={this.state.values[form]}
						onSubmit={(data) => this.handleSubmit(form, data)}
						readOnly={this.state.readOnly[form]}
						onValidationChange={(target) => this.handleValidationChange(form, target)}
						onAcceptanceChange={(hasAccepted) => this.handleAcceptanceChange(form, hasAccepted)}
						disabled={!steps.includes(form)}
						urlPrefix="app"
					>
						<Row>
							<Col className="text-right">
								<Button color="success" disabled={!hasAccepted[form]}><T>update</T></Button>
							</Col>
						</Row>
					</Form>
				</Card>
			)
		);
	}

}

const mapStateToProps = (state) => ({
	user: state.profile.user,
});

InitiateOrg = connect(mapStateToProps)(InitiateOrg);

export default withApp(InitiateOrg);
