import React, { Component } from 'react';
import { Card, CardHeader, CardBody, Row, Col, Button, Form as ReactForm, FormGroup, Input, Label } from 'reactstrap';
import { CNav, CNavItem, CNavLink, CTabContent, CTabPane } from '@coreui/react';

import withApp from './app';
import { StaticRoutes } from '../../model/routes';
import { Form } from 'flows/views/components';
import { uploadData } from 'core/ducks/upload'
import T from 'modules/i18n';

class Foreign extends Component {

	constructor(props) {
		super(props);
		this.forms = {foreign_beneficiary: 'beneficiary_details', foreign_beneficiary_properties: 'beneficiary_properties', legal_representative: 'legal_representative', foreign_representation: 'representation certificate'};
		this.state = {
			activeTab: 0,
			values: Object.keys(this.forms).reduce((obj, form) => ({...obj, [form]: {}}), {}),
			hasAccepted: Object.keys(this.forms).reduce((obj, form) => ({...obj, [form]: false}), {}),
			formDetails: Object.keys(this.forms).reduce((obj, form) => ({...obj, [form]: {}}), {}),
			steps: [0],
		};
	}

	setActiveTab = (activeTab) => {
		if (this.state.activeTab !== activeTab)
			this.setState({activeTab});
	}

	handleIndividualSubmit = (data, form) => {
		let values = {};
		data.forEach((value, key) => {
			values = {...values, [key]: value}
		});
		this.setState({
			values: {
				...this.state.values,
				[form]: values,
			},
			activeTab: this.state.activeTab + 1,
			steps: [...this.state.steps, this.state.activeTab + 1]
		});
	}

	handleAcceptanceChange = (scope, hasAccepted) => {
		this.setState((prevState, props) => ({
			hasAccepted: {...prevState.hasAccepted, [scope]: hasAccepted}
		}));
	}

	detectChange = (form) => {
		const step = Object.keys(this.forms).indexOf(form);
		if (step < this.state.steps.length - 1)
			this.setState({
				steps: this.state.steps.slice(0, step + 1),
			});
	}

	getFormDetails = (details, form) => {
		this.setState({
			formDetails: {...this.state.formDetails, [form]: details},
		});
	}

	handleSubmit = (e) => {
		e.preventDefault();
		const formData = new FormData();
		Object.entries(this.state.values).forEach(([form, values]) => {
			Object.entries(values).forEach(([key,  value]) => {
				formData.append(`${form}-${key}`, key === 'country' ? JSON.parse(value).value : value);
			});
		});
		this.props.dispatch(uploadData('app/foreign', formData)).then(() => {
			this.props.getDefaultProfile(true, () => {
				this.props.history.replace(StaticRoutes.AppDashboard);
			});
		});
	}

	render() {
		const forms = Object.keys(this.forms);
		return (
			<Card className="m-2 p-2 w-md-75">
				<CardHeader tag="h4"><T>foreign company details</T></CardHeader>
				<CardBody>
					<CNav variant="pills" layout="justified" role="tablist">
						{ forms.map((form, id) => (
							<CNavItem key={`foreign-nav-item-${id}`}>
								<CNavLink
									href="#"
									onClick={() => this.setActiveTab(id)}
									active={this.state.activeTab === id}
									disabled={!this.state.steps.includes(id)}
								>
									<T>{this.forms[form]}</T>
								</CNavLink>
							</CNavItem>
						))}
						<CNavItem>
							<CNavLink
								href="#"
								onClick={() => this.setActiveTab(4)}
								active={this.state.activeTab === 4}
									disabled={!this.state.steps.includes(4)}
							>
								<T>completion</T>
							</CNavLink>
						</CNavItem>
					</CNav>
					<div className="mt-2"><div className="mx-auto border-bottom w-75"/></div>
					<CTabContent>
						{ forms.map((form, id) => (
							<CTabPane key={`foreign-tabpane-form-${form}`} role="tabpanel" visible={this.state.activeTab === id}>
								<Form
									form={form}
									onSubmit={(data) => this.handleIndividualSubmit(data, form)}
									onAcceptanceChange={(hasAccepted) => this.handleAcceptanceChange(form, hasAccepted)}
									onChange={() => this.detectChange(form)}
									getFormDetails={(details) => this.getFormDetails(details, form)}
								>
									<Row>
										<Col className="d-flex justify-content-end">
											<Button className="mr-3" color="success" disabled={!this.state.hasAccepted[form]}>
												<i className="fa fa-forward mr-2"/><T>next</T>
											</Button>
										</Col>
									</Row>
								</Form>
							</CTabPane>
						))}
						<CTabPane role="tabpanel" visible={this.state.activeTab === 4}>
							<p className="h5"><T>you are about to register the organization with the following details</T>:</p>
							<Card>
								<CardBody>
									{ this.state.activeTab === 4 &&
										<ReactForm onSubmit={this.handleSubmit}>
											{ ['country', 'title', 'vat_number', 'type'].map(field => (
												<FormGroup row key={`foreign_representation_completion_${field}`} className="m-2 p-2">
													<Col lg="4" className="mr-2 text-lg-right">
														<Label className="application-label">
															{this.state.formDetails.foreign_beneficiary.find(f => f.name === field).label}
														</Label>
													</Col>
													<Col lg="7" className="pr-lg-5">
														<FormGroup>
															<Input
																defaultValue={ field==='country'
																	? JSON.parse(this.state.values.foreign_beneficiary[field]).label
																	: this.state.values.foreign_beneficiary[field]
																}
																readOnly
															/>
														</FormGroup>
													</Col>
												</FormGroup>
											))}
											<Row>
												<Col className="d-flex justify-content-end">
													<Button className="mr-2" color="info"><T>complete record</T></Button>
												</Col>
											</Row>
										</ReactForm>
									}
								</CardBody>
							</Card>
						</CTabPane>
					</CTabContent>
				</CardBody>
			</Card>
		);
	}

}

export default withApp(Foreign);
