import React, {Component} from 'react';
import {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Dropdown
} from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';

import { logout } from 'core/ducks/profile';
import { SecureContent } from 'core/components';
import * as roles from 'core/model/roles';
import { StaticRoutes } from 'core/model/routes';
import { userMenuItems } from 'modules';
import T from 'modules/i18n';

class HeaderDropdown extends Component {

	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);
		this.state = {
			dropdownOpen: false
		};
	}

	toggle() {
		this.setState({
			dropdownOpen: !this.state.dropdownOpen
		});
	}

	logout = () => {
		this.props.dispatch(logout())
			.then(() => this.props.history.push(StaticRoutes.Logout));
	}

	render() {
		const profile = this.props.profile;
		return (
			<SecureContent role={roles.AUTHORIZED}>
				<Dropdown nav isOpen={this.state.dropdownOpen} toggle={this.toggle}>
					<DropdownToggle caret size="sm" className="no-outline text-info" nav>
						<i className="fa fa-user-circle" style={{fontSize: '150%'}}/>
					</DropdownToggle>
					<DropdownMenu right className="p-0">
						<DropdownItem header tag="div" className="bg-info text-center text-white">
							<i className="fa fa-user-circle" style={{fontSize: '150%'}}/>
						</DropdownItem>
						<DropdownItem header tag="div" className="text-center">
								<div className="text-info">{ profile.firstname } { profile.lastname }</div>
								<div className="fw-semibold">{profile.user_id}</div>
								<div><T>{profile.role}</T></div>
						</DropdownItem>
						<DropdownItem divider/>
						<SecureContent role={roles.REVIEWER}>
							<NavLink to={StaticRoutes.Profile} className="dropdown-item" onClick={this.toggle}>
								<i className="fa fa-user"/> <T>profile</T>
							</NavLink>
						</SecureContent>
						{
							profile.role === 'authorized' &&
								<SecureContent role={roles.AUTHORIZED}>
									<NavLink to={StaticRoutes.ProfileAuthorized} className="dropdown-item" onClick={this.toggle}>
										<i className="fa fa-user"/> <T>profile</T>
									</NavLink>
								</SecureContent>
						}
						{ userMenuItems.map((item, index) => (
							<SecureContent key={`custom_user_menu_item_${index}`} role={item.role}>
								<NavLink to={item.route} className="dropdown-item" onClick={this.toggle}>
									<i className={item.className || ''}/> <T>{item.name}</T>
								</NavLink>
							</SecureContent>
						))}
						<DropdownItem divider/>
						<DropdownItem onClick={this.logout}>
							<i className="fa fa-lock mr-2 text-warning"></i> <T>logout</T>
						</DropdownItem>
					</DropdownMenu>
				</Dropdown>
			</SecureContent>
		);
	}
}

const mapStateToProps = (state) => ({
	profile: state.profile.user,
});

HeaderDropdown = connect(mapStateToProps)(HeaderDropdown);

export default withRouter(HeaderDropdown);
