export const applicationStatusStyle = {
	'progressing': { color: 'darkgoldenrod', badge: 'secondary' },
	'with_editor': { color: 'blue', badge: 'primary' },
	'under_review': { color: 'blue', badge: 'primary' },
	'under_editor_evaluation': { color: 'blue', badge: 'primary' },
	'rejected': { color: 'red', badge: 'danger' },
	'completed': { color: 'green', badge: 'success' },
}

export const applicationStatus = Object.keys(applicationStatusStyle);

export const applicationTypes = [
	'boolean',
	'categorical',
	'form',
	'workflow',
	'transition',
	'assignation',
	'register',
	'email',
	'submit',
	'rf',
	'certificate',
	'delay',
	'modification'
];

export const applicationContentTypes = {
	'O': 'Operational',
	'I': 'Intermediate',
	'P': 'Product',
	'E': 'External',
};

export const applicationRoles = [
	'author',
	'reviewer',
	'editor',
];

export const fieldTypes = [
	'string',
	'text',
	'attachment',
	'map',
	'radio',
	'select',
	'number',
	'date',
	'checkbox',
	'plaintext',
	'conditional',
	'end-conditional',
	'email',
	'vat_number',
	'accept',
	'country',
	'application',
	'category'
];
