import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AsyncPaginate } from 'react-select-async-paginate';

class AsyncPaginateWrapper extends Component {

	constructor(props) {
		super(props);
		this.state = {
			hiddenInputValue: this.getValueFromObj(props.value),
		};
		this.selectRef = React.createRef();
	}

	getValueFromObj = (obj) => {
		return (obj === null || obj === '') ? '' : (this.props.isMulti ? obj.map(e => e.value).join(';') : obj.value);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.value !== this.props.value) {
			const hiddenInputValue = this.getValueFromObj(this.props.value);
			this.setState({hiddenInputValue});
			this.selectRef.current.blur();
		}
	}

	render() {
		const { inputProps, disabled, readOnly, value, ...otherProps } = this.props;
		return (
			<>
				<AsyncPaginate
					selectRef={this.selectRef}
					{...otherProps}
					value={value === null ? '' : value} 
					onChange={this.props.onChange} 
					isSearchable={!disabled && !readOnly} 
					isDisabled={disabled || readOnly}
				/>
				<input
					tabIndex={-1}
					autoComplete="off"
					style={{
						opacity: 0,
						width: "100%",
						height: 0,
						position: "absolute"
					}}
					value={this.state.hiddenInputValue}
					onChange={() => {}}
					{...inputProps}
				/>
			</>
		)
	}
}

AsyncPaginateWrapper.propTypes = {
	value: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.oneOf([''])]),
	inputProps: PropTypes.object,
	disabled: PropTypes.bool,
	readOnly: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
	isMulti: PropTypes.bool,
};

AsyncPaginateWrapper.defaultProps = {
	value: '',
	inputProps: {},
	disabled: false,
	readOnly: false,
	isMulti: false
}

export default AsyncPaginateWrapper;
