import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Col, Button as ReactstrapButton } from 'reactstrap';

import * as roles from 'core/model/roles';
import {
	Filter, FilterGroup, Table, Title, Button, Tbody, Thead, Toolbox, Tr, Search, Pagination, Checkbox
} from 'table';
import { StaticRoutes, DynamicRoutes } from '../../model/routes';
import { buildPath, getNamedParams } from 'core/model/lib/urlTools';
import { requestData, getData, getInfo } from 'core/ducks/list';
import { getData as getAPIData } from 'core/ducks/update';
import { Loading } from 'core/components';
import { ErrorPage } from 'core/views/pages';
import { applicationStatus, applicationStatusStyle } from '../../model/applicationStatus';
import { AssignModal, SetDelayModal, FieldSelectionModal } from '../modals';
import { default as T, getLocale } from 'modules/i18n';

class Application extends Component {

	constructor(props) {
		super(props);
		this.initialState = {
			query: '',
			sort: 'created',
			sort_method: 'desc',
			httpStatus: 200,
			allChecked: false,
			included: [],
			isSetDelayModalOpen: false,
			selectedApplication: {},
			additionalFields: [],
			additionalLayout: {},
			isFieldSelectionModalOpen: false,
			exportMode: false,
			workflowFields: {},
		};
		this.state = { ...this.initialState, page: 1, refreshing: false, mounted: true, assign: null, steps: [] };

		this.fields = props.profile.role === roles.AUTHORIZED
			? ['round_label', 'registry_number', 'created', 'last_action', 'status',]
			: ['workflow', 'round_label', 'organization', 'organization_type', 'step', 'registry_number', 'created', 'last_action', 'reviewer', 'status',];

		this.layout = {
			workflow: { sortable: true },
			round_label: { sortable: true },
			organization_type: { type: 'translatable' },
			registry_number: { sortable: true },
			created: { type: 'date', sortable: true },
			last_action: { type: 'date', sortable: true },
			status: { type: 'translatable' },
		};
		if (props.profile.role !== roles.AUTHORIZED) {
			this.layout.reviewer = { sortable: true };
		}

		this.badge_colors = Object.keys(applicationStatusStyle).reduce((obj, key) => ({
			...obj,
			[key]: applicationStatusStyle[key].badge,
		}), {});

		this.fetchData = this.fetchData.bind(this);
		this.createUrl = this.createUrl.bind(this);
		this.handlePageChange = this.handlePageChange.bind(this);
		this.handleFilterChange = this.handleFilterChange.bind(this);
		this.handleToolboxReset = this.handleToolboxReset.bind(this);
		this.handleSortChange = this.handleSortChange.bind(this);
	}

	componentDidMount() {
		this.fetchData();

		this.props.dispatch(
			requestData('workflow', 'flows/workflow/filter/visible/fields/mname;name')
		).catch(httpStatus => {
			this.setState({ httpStatus });
		});

		const params = getNamedParams(this.props.location.pathname, this.props.match.path);
		if (params.workflow) {
			this.handleWorkflowParam(params.workflow);
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const params = getNamedParams(this.props.location.pathname, this.props.match.path);
		const prevParams = getNamedParams(prevProps.location.pathname, prevProps.match.path);
		if (prevState.query !== this.state.query || params.workflow !== prevParams.workflow || params.round !== prevParams.round || params.step !== prevParams.step || params.status !== prevParams.status) {
			this.setState({page: 1}, this.fetchData);
		}
		if (params.workflow !== prevParams.workflow) {
			if (!params.workflow) {
				this.setState({steps: [], additionalFields: [], additionalLayout: {}});
			} else {
				this.handleWorkflowParam(params.workflow);
			}
		}
	}

	handleWorkflowParam = (workflow) => {
		this.props.dispatch(
			requestData('round', `flows/round/workflow/${workflow}/sort/starts`)
		).catch(httpStatus => {
			this.setState({ httpStatus });
		});
		this.props.dispatch(
			getAPIData(`flows/info/workflow/${workflow}/scope/node`)
		).then(steps => this.setState({steps}));
		let additionalFields = JSON.parse(localStorage.getItem(`table_fields.${workflow}`));
		if (!additionalFields) {
			this.props.dispatch(
				getAPIData(`flows/info/workflow/${workflow}/scope/fields`)
			).then(workflowFields => {
				additionalFields = Object.values(workflowFields).map(e => e.fields).flat().filter(e => e.view);
				localStorage.setItem(`table_fields.${workflow}`, JSON.stringify(additionalFields));
				const additionalLayout = additionalFields.reduce((obj, e) => ({
					...obj,
					[e.name]: {map: e.label},
				}), {});
				this.setState({additionalFields: additionalFields.map(e => e.name), additionalLayout});
			});
		} else {
			const additionalLayout = additionalFields.reduce((obj, e) => ({
				...obj,
				[e.name]: {map: e.label},
			}), {});
			this.setState({additionalFields: additionalFields.map(e => e.name), additionalLayout});
		}
	}

	fetchData() {
		const url = this.createUrl();
		this.props.dispatch(
			requestData('application', url)
		).catch(httpStatus => {
			this.setState({ httpStatus });
		});
	}

	createUrl(base_url='flows/application') {
		let { query, sort, sort_method } = this.state;
		let url = base_url === 'flows/application' ? `${base_url}/page/${this.state.page}` : base_url;
		url = `${url}/sort/${sort}/sort_method/${sort_method}`;
		query = (query !== '') ? '/query/' + query : '';
		const filters = getNamedParams(this.props.location.pathname, this.props.match.path);
		let fq = [];
		['workflow', 'round', 'step', 'status'].forEach(filter => {
			if (filters[filter] && filters[filter] !== ''){
				fq.push(`${filter}:${filters[filter]}`);
			}

		});
		if (this.props.onlyPending) {
			fq.push('status:under_review');
			if (this.props.user.role === roles.REVIEWER) {
				fq.push(`reviewer:${this.props.user.uuid}`);
			} else {
				fq.push('status:progressing');
			}
		}
		if (fq.length > 0) {
			fq = fq.join(';');
			fq = `/fq/${fq}`;
		} else {
			fq = '';
		}
		url += query + fq;

		return url;
	}

	handlePageChange(page) {
		this.setState({ page }, this.fetchData);
	}

	handleFilterChange(event) {
		const { name, value } = event.target;
		this.setState({page: 1}, () => {
			const currentParams = name !== 'workflow' ? {[name]: value} : {[name]: value, round: null, step: null};
			const { status, workflow, round, step } = {...getNamedParams(this.props.location.pathname, this.props.match.path), ...currentParams};
			const route = this.props.profile.role !== roles.AUTHORIZED ? DynamicRoutes.AdminApplicationsStatus : DynamicRoutes.ApplicationsStatus;
			const url = buildPath(route, {status, workflow, round, step});
			this.props.history.push(url);
		});
	}

	handleQueryChange = ({target}) => {
		const { value } = target;
		this.setState({query: value});
	}

	handleToolboxReset() {
		this.setState({
			...this.initialState,
			page: 1
		}, this.fetchData);
		let url = StaticRoutes.Applications;
		if (this.props.profile.role !== roles.AUTHORIZED) {
			url = StaticRoutes.AdminApplications;
		}
		this.props.history.push(url);
	}

	handleSortChange(sort) {
		if (sort === this.state.sort) {
			this.setState({ sort_method: this.state.sort_method === 'asc' ? 'desc' : 'asc' }, this.fetchData);
		} else {
			this.setState({ sort }, this.fetchData);
		}
	}

	viewDetails({ round, index }) {
		let url = DynamicRoutes.Apply;
		if (this.props.profile.role !== roles.AUTHORIZED) {
			url = DynamicRoutes.AdminApply;
		}
		const path = buildPath(url, [round, index]);
		this.props.history.push(path);
	}

	downloadAttachments = ({ index }) => {
		const url = `/api/flows/applicationFiles/application/${index}`;
		window.open(url);
	}

	handleCheckboxChange = (args) => {
		const { index: application_uuid, checked } = args;
		if (this.props.data[application_uuid].status === applicationStatus[3] || this.props.data[application_uuid].status === applicationStatus[4]) {
			const { included } = this.state;
			const state = {};
			const index = included.indexOf(application_uuid);
			if (checked) {
				if (index === -1) {
					state.included = [...included, application_uuid];
				}
			} else {
				if (index !== -1) {
					state.included = [
						...included.slice(0, index),
						...included.slice(index + 1),
					];
				}
			}
			this.setState({ ...state });
		}
	}

	handleAssign = () => {
		if (this.state.included.length > 0)
			this.setState({ assign: this.state.included })
	}

	setDelay = (selectedApplication) => {
		this.setState({
			isSetDelayModalOpen: true,
			selectedApplication
		});
	}

	setAdditionalFields = (additionalFields) => {
		const filters = getNamedParams(this.props.location.pathname, this.props.match.path);
		localStorage.setItem(`table_fields.${filters.workflow}`, JSON.stringify(additionalFields));
		const additionalLayout = additionalFields.reduce((obj, e) => ({
			...obj,
			[e.name]: {map: e.label},
		}), {});
		this.setState({additionalFields: additionalFields.map(e => e.name), additionalLayout, isFieldSelectionModalOpen: false});
	}

	setExportFields = (exportFields) => {
		const fields = exportFields.map(f => f.name).join(';');
		this.setState({isFieldSelectionModalOpen: false, exportMode: false}, () => {
			const locale = this.props.dispatch(getLocale());
			const url = `${this.createUrl('/api/flows/export')}/locale/${locale}/format/xlsx/form_fields/${fields}`;
			window.open(url);
		});
	}

	handleFieldSelect = (state={}) => {
		const params = getNamedParams(this.props.location.pathname, this.props.match.path);
		if (!params.workflow)
			return;
		this.props.dispatch(
			getAPIData(`flows/info/workflow/${params.workflow}/scope/fields`)
		).then(workflowFields => this.setState({isFieldSelectionModalOpen: true, workflowFields, ...state}));
	}

	handleExport = () => {
		const params = getNamedParams(this.props.location.pathname, this.props.match.path);
		if (params.workflow) {
			this.handleFieldSelect({exportMode: true});
		} else {
			const locale = this.props.dispatch(getLocale());
			const url = `${this.createUrl('/api/flows/export')}/locale/${locale}/format/xlsx`;
			window.open(url);
		}
	}

	render() {

		const { data, info, list } = this.props;
		const filters = getNamedParams(this.props.location.pathname, this.props.match.path);
		if (this.state.httpStatus !== 200)
			return (<ErrorPage status={this.state.httpStatus} />);
		if (list.application.status !== 200 && list.application.status !== '')
			return (<ErrorPage status={list.application.status} />);

		const { messages } = this.props.i18n || { messages: {} };

		return (
			<Row>
				<Col>
					<Table>
						<Title>
							<T>applications</T>
							<Button type="toolbox" title="filters" className="float-right" />
							{(this.props.user.role === roles.ADMIN || this.props.user.role === roles.EDITOR) &&
								<Button type="assign" title={messages.assignation || 'Ανάθεση'} className="float-right" onClick={this.handleAssign} />
							}
						</Title>
						<Toolbox onReset={this.handleToolboxReset}>
							<Row>
								<Col xs="12" lg="8" className="form-group text-right">
									<Row>
										<Col>
											<Search placeholder={`${messages.organization} ή ${messages.registration_number || 'search'}`} onChange={this.handleQueryChange} />
											{[roles.ADMIN, roles.EDITOR, roles.REVIEWER].includes(this.props.user.role) &&
												<Button type="xlsx" title={`${messages.export || 'export'} .xlsx`} className="mx-2" onClick={this.handleExport}>
													<T>export</T> .xlsx
												</Button>
											}
											<Button type="resetFilters" title={messages['reset filters'] || 'reset filters'}><T>reset</T></Button>
										</Col>
									</Row>
									{ filters.workflow &&
										<Row className="p-5">
											<Col>
												<ReactstrapButton outline color="success" onClick={this.handleFieldSelect}>
													<T>select fields</T>
												</ReactstrapButton>
											</Col>
										</Row>
									}
								</Col>
								<Col xs="12" lg="4">
									{[roles.ADMIN, roles.EDITOR, roles.REVIEWER].includes(this.props.user.role) &&
										<FilterGroup>
											<Filter onChange={this.handleFilterChange} name="workflow" defaultValue={filters.workflow}>
												<option value="">{`${messages.choose || 'choose'} ${messages.workflow || 'workflow'}`}</option>
												{Object.keys(list.workflow.data).map((r) => <option key={`option_${r}`} value={r}>{list.workflow.data[r].name}</option>)}
											</Filter>
											{ filters.workflow &&
												<Filter onChange={this.handleFilterChange} name="round" defaultValue={filters.round} >
													<option value="">{`${messages.choose || 'choose'} ${messages.round_status || 'round'}`}</option>
													{Object.keys(list.round.data).map((r) => <option key={`option_${r}`} value={r}>{list.round.data[r].label}</option>)}
												</Filter>
											}
											{ filters.workflow &&
												<Filter onChange={this.handleFilterChange} name="step" defaultValue={filters.step} >
													<option value="">{`${messages.choose || 'choose'} ${messages.step || 'step'}`}</option>
													{this.state.steps.map(n => <option key={`option_${n.mname}`} value={n.mname}>{n.label}</option>)}
												</Filter>
											}
										</FilterGroup>
									}
									<FilterGroup>
										<Filter onChange={this.handleFilterChange} name="status" defaultValue={filters.status} >
											<option value="">{`${messages.choose || 'choose'} ${messages.status || 'status'}`}</option>
											{applicationStatus.map((status) => (
												<option key={`option_${status}`} value={status}>{messages[status] || 'status'}</option>
											))}
										</Filter>
									</FilterGroup>
								</Col>
							</Row>
						</Toolbox>
						<Thead>
							<Tr
								className="text-capitalize"
								data={['', ...this.fields, ...this.state.additionalFields]}
								layout={{...this.layout, ...this.state.additionalLayout}}
								sortBy={this.state.sort}
								sortMethod={this.state.sort_method}
								onClick={this.handleSortChange}
							/>
						</Thead>
						{
							this.props.pending || !data ? <Loading /> :
								<Tbody refreshing={this.state.refreshing}>
									<Tr
										data={data ? data : {}}
										layout={{...this.layout, ...this.state.additionalLayout}}
										order={[...this.fields, ...this.state.additionalFields]}
										badge_colors={this.badge_colors}
									>
										{(this.props.user.role === roles.ADMIN || this.props.user.role === roles.EDITOR) &&
											<Checkbox allChecked={this.state.allChecked} list={this.state.included} onChange={this.handleCheckboxChange} className="mr-2" />
										}
										<Button type="view" title={messages.view || 'view'} onClick={(data) => { this.viewDetails(data) }} />
										{[roles.ADMIN, roles.EDITOR, roles.REVIEWER].includes(this.props.user.role) &&
											<Button type="file-archive-o" title={messages.attachments || 'attachments'} onClick={(data) => { this.downloadAttachments(data) }} />
										}
										{
											// [roles.ADMIN, roles.EDITOR].includes(this.props.user.role) &&
											// <Button type="hourglass-half" title={messages.delay || 'delay'} onClick={(data) => { this.setDelay(data) }} />
										}
									</Tr>
								</Tbody>
						}
						<Pagination
							className="mx-auto"
							page={info.page || 1}
							total={info.total_pages || 1}
							onClick={(page) => {
								if (page !== info.page)
									this.handlePageChange(page);
							}}
						/>
					</Table>
				</Col>
				{this.state.assign &&
					<AssignModal
						isOpen={this.state.assign ? true : false}
						toggle={() => {
							this.setState({ assign: null });
							this.fetchData();
						}}
						data={this.state.assign}
					/>
				}
				{ this.state.isSetDelayModalOpen && (
					<SetDelayModal
						isOpen={this.state.isSetDelayModalOpen}
						toggle={() => this.setState({isSetDelayModalOpen: false, selectedApplication: {}})}
						application={this.state.selectedApplication}
					/>
				)}
				{ this.state.isFieldSelectionModalOpen &&
					<FieldSelectionModal
						isOpen={this.state.isFieldSelectionModalOpen}
						toggle={() => this.setState({isFieldSelectionModalOpen: false})}
						fields={this.state.workflowFields}
						setAdditionalFieldsCallback={this.state.exportMode ? this.setExportFields : this.setAdditionalFields}
						values={JSON.parse(localStorage.getItem(`table_fields.${filters.workflow}`))}
					/>
				}
			</Row>
		);
	}
}

const mapStateToProps = (state) => ({
	profile: state.profile.user,
	list: state.list,
	pending: state.list.application.pending,
	data: getData(state, 'application'),
	info: getInfo(state, 'application'),
	round: getData(state, 'round'),
	viewData: state.update.response,
	http_status: state.update.status,
	i18n: state.i18n,
	user: state.profile.user
});

Application.propTypes = {
	onlyPending: PropTypes.bool, // Displays only pending applications for logged in reviewer
	withoutToolBox: PropTypes.bool // Does not display the toolbox
};

Application = connect(mapStateToProps)(Application);

export default Application;
